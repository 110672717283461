import React, { useState, useEffect, useRef } from "react";
import userProfile from "../assests/people2.png";
import { RiRadioButtonLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs"; // Three dots icon
import api from '../services/api';

const Conversation = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [contact, setContact] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [imageModal, setImageModal] = useState({ isOpen: false, imageUrl: "" });
  const [sessionId, setSessionId] = useState(null);
  const [dropdownMessageId, setDropdownMessageId] = useState(null); // To track open dropdown for messages
  const location = useLocation();
  const navigate = useNavigate();
  const messagesEndRef = useRef(null); // Ref for scrolling to the bottom

  const queryParams = new URLSearchParams(location.search);
  const receiverId = queryParams.get("id");

  const handleMenuToggle = () => setMenuOpen(!menuOpen);

  const handleClickOutside = (event) => {
    if (!event.target.closest("#menuButton") && !event.target.closest("#menuDropdown")) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const response = await api.getSession();
        if (response && response.user && response.user.id) {
          setSessionId(response.user.id);
        } else {
          console.error("No user data in response");
        }
      } catch (error) {
        console.error("Failed to fetch session:", error.message);
      }
    };
    fetchSession();
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  useEffect(() => {
    if (receiverId) {
      const fetchContactAndMessages = async () => {
        try {
          const contactResponse = await api.fetchContactById(receiverId);
          if (contactResponse.success) {
            setContact(contactResponse.contact);
          } else {
            console.error("Failed to fetch contact:", contactResponse.message);
          }

          const messagesResponse = await api.fetchConversation(receiverId);
          if (messagesResponse.success) {
            setMessages(messagesResponse.messages);
          } else {
            console.error("Failed to fetch messages:", messagesResponse.message);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchContactAndMessages();
    }
  }, [receiverId]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleNewMessageChange = (event) => setNewMessage(event.target.value);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleImageClick = (imageUrl) => setImageModal({ isOpen: true, imageUrl });

  const closeImageModal = () => setImageModal({ isOpen: false, imageUrl: "" });

  const handleSendMessage = async () => {
    if (!sessionId) {
      console.error("Session ID is missing");
      return;
    }
    if (newMessage.trim() === "" && !selectedFile) {
      console.error("Message or file is missing");
      return;
    }

    const tempMessage = {
      id: Date.now(),
      message: newMessage,
      image_url: isUploading ? "loading" : null,
      is_sent_by_current_user: true,
    };
    setMessages((prevMessages) => [...prevMessages, tempMessage]);
    setIsUploading(true);

    const formData = new FormData();
    formData.append("receiver_id", receiverId);
    formData.append("message", newMessage);
    formData.append("session_id", sessionId);
    if (selectedFile) formData.append("file", selectedFile);

    try {
      const response = await api.sendMessage(formData);
      setIsUploading(false);

      if (response.success) {
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === tempMessage.id ? { ...msg, image_url: response.image_url } : msg
          )
        );
        setNewMessage("");
        setSelectedFile(null);

        const updatedMessagesResponse = await api.fetchConversation(receiverId);
        if (updatedMessagesResponse.success) {
          setMessages(updatedMessagesResponse.messages);
        } else {
          console.error("Failed to fetch updated messages:", updatedMessagesResponse.message);
        }
      } else {
        console.error("Failed to send message:", response.message);
      }
    } catch (error) {
      setIsUploading(false);
      console.error("Error sending message:", error.message);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    console.log("Deleting message with ID:", messageId); // Log messageId
  
    try {
      const response = await api.deleteMessage(messageId);
      if (response.success) {
        setMessages(messages.filter((msg) => msg.id !== messageId));
      } else {
        console.error("Failed to delete message:", response.message);
      }
    } catch (error) {
      console.error("Error deleting message:", error.message);
    }
  };

  const goToUserProfile = () => navigate(`/profile/specificUser?professional_id=${receiverId}`);

  return (
    <div className="flex flex-col max-w-6xl md:mt-10 md:mx-auto m-2 h-[700px] rounded-xl">
      <div className="flex-1">
        <header className="bg-white py-6 px-1 text-gray-700 flex justify-between items-center border-b-2">
          <div className="flex items-center">
            <img className="rounded-full w-16 h-16" src={contact?.ProfilePicture || userProfile} alt="profile" />
            <RiRadioButtonLine color="green" className="absolute right-1 top-9" />
            <h1 className="text-lg font-normal ml-4">{contact?.Name || "Loading..."}</h1>
            <button onClick={goToUserProfile} className="bg-lime-500 text-white ml-4 px-2 py-1 rounded-lg">
              View Profile
            </button>
          </div>
        </header>

        <div className="flex flex-col bg-white h-[700px]">
          <div className="overflow-y-auto p-4 pb-36 flex-1">
            {messages.map((msg) => (
              <div key={msg.id} className={`flex mb-4 ${msg.is_sent_by_current_user ? "justify-end" : "justify-start"}`}>
                
                <div className="relative flex items-start">
                  {msg.is_sent_by_current_user && (
                    <div className="mr-2">
                      {/* Horizontal Dots Icon */}
                      <span
                        className="cursor-pointer text-gray-600 text-3xl"
                        onClick={() => setDropdownMessageId(dropdownMessageId === msg.id ? null : msg.id)}
                      >
                        &#8230; {/* Horizontal dots character */}
                      </span>
                      {dropdownMessageId === msg.id && (
                        <div className="absolute left-[-40px] mt-2 w-32 bg-white border border-gray-300 rounded-md shadow-lg z-10">
                          <button
                            onClick={() => handleDeleteMessage(msg.id)}
                            className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                          >
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  
                  <div className={`flex items-center ${msg.is_sent_by_current_user ? "bg-lime-500 text-white" : "bg-gray-200 text-black"} rounded-lg p-3 max-w-xs gap-3`}>
                    <p className="text-xs sm:text-base">{msg.message}</p>
                
                    {msg.image_url && (
                      msg.image_url.endsWith(".pdf") ? (
                        <a
                          href={msg.image_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 underline"
                        >
                          View PDF
                        </a>
                      ) : msg.image_url.endsWith(".doc") || msg.image_url.endsWith(".docx") ? (
                        <a
                          href={msg.image_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 underline"
                        >
                          View Document
                        </a>
                      ) : (
                        <img
                          src={msg.image_url}
                          alt="Attachment"
                          className="w-16 h-16 object-cover rounded-lg cursor-pointer"
                          onClick={() => handleImageClick(msg.image_url)}
                        />
                      )
                    )}
                             {msg.created_at && (
          <p className="text-xs text-gray-500 mt-2">
            {new Date(msg.created_at).toLocaleString()}
          </p>
          
        )}
                  </div>
           
                </div>
               
       
    
              </div>
              
            ))}
            <div ref={messagesEndRef} />
          
          </div>

          <footer className="bg-white border-t border-gray-300 p-4">
            <div className="flex flex-col sm:flex-row items-center sm:space-x-4 space-y-4 sm:space-y-0">
              <label className="sm:w-auto w-full text-gray-700 cursor-pointer bg-gray-200 py-2 px-4 rounded-lg">
                Attach File
                <input
                  type="file"
                  accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  onChange={handleFileChange}
                  className="sm:w-auto w-full mt-2"
                />
              </label>
              <input
                type="text"
                placeholder="Type your message..."
                value={newMessage}
                onChange={handleNewMessageChange}
                className="flex-1 border-2 border-gray-300 rounded-lg py-2 px-4"
              />
              <button
                onClick={handleSendMessage}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg sm:w-auto w-full"
              >
                Send
              </button>
            </div>
          </footer>
        </div>
      </div>

      {/* Image Modal */}
      {imageModal.isOpen && (
        <div className="z-[9999] fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center" onClick={closeImageModal}>
          <img src={imageModal.imageUrl} alt="Full-size Image" className="max-w-full max-h-full" />
        </div>
      )}
    </div>
  );
};

export default Conversation;
