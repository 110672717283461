import React from 'react';
import joby from '../assests/joby.gif'; // Correctly import the gif file

const ChatBotIcon = ({ onClick }) => {
  return (
    <div className="  fixed bottom-5 right-5 flex items-center space-x-2 z-[9999]">
      {/* ChatBot Icon */}
      <div
        onClick={onClick}
        className="  bg-white p-3 rounded-full shadow-lg cursor-pointer"
      >
        <img src={joby} alt="ChatBot Icon" className="  w-12 h-12" />
      </div>
    </div>
  );
};

export default ChatBotIcon;
