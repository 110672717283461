import React from 'react';
import { PiTelegramLogoLight, PiWhatsappLogoLight } from "react-icons/pi";
import { MdAlternateEmail } from "react-icons/md";
import { IoShareSocialOutline } from "react-icons/io5";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
const Share = ({ profileUrl, closeModal }) => {
    // Function to copy the URL to the clipboard
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(profileUrl);
            alert('Link copied to clipboard!');
        } catch (error) {
            alert('Unable to copy link. Please copy it manually.');
        }
    };

    // Function to share via email
    const shareViaEmail = () => {
        const url = `mailto:?subject=Check%20this%20out&body=${encodeURIComponent(profileUrl)}`;
        openShareLink(url);
    };

    // Function to share via WhatsApp
    const shareViaWhatsApp = () => {
        const url = `https://wa.me/?text=${encodeURIComponent(profileUrl)}`;
        openShareLink(url);
    };

    // Function to share via Telegram
    const shareViaTelegram = () => {
        const url = `https://t.me/share/url?url=${encodeURIComponent(profileUrl)}`;
        openShareLink(url);
    };

    // Function to share via X
    const shareViaX = () => {
        const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(profileUrl)}`;
        openShareLink(url);
    };

    // Function to share via Facebook
    const shareViaFacebook = () => {
        const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(profileUrl)}`;
        openShareLink(url);
    };

    // Function to alert for Instagram
    const shareViaInstagram = () => {
        alert("Instagram doesn't support direct URL sharing. You can copy the link and share it in your bio or stories.");
    };

    // Fallback for Safari popup blocking
    const openShareLink = (url) => {
        const newWindow = window.open(url, '_blank');
        if (!newWindow) {
            window.location.href = url;
        }
    };

    return (
        <div className="fixed inset-0 bg-black/50 flex justify-center items-center z-10">
            <div className="bg-white p-6 rounded-lg shadow-lg w-[500px] relative">
                <div className="absolute top-4 right-4 text-gray-500 cursor-pointer" onClick={closeModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
                <h2 className="text-lg font-semibold mb-4 text-center">Partager avec</h2>
                <div className="flex justify-around mb-6 flex-wrap gap-4">
                    <div className="flex flex-col items-center cursor-pointer" onClick={shareViaEmail}>
                        <div className="bg-gray-200 rounded-full p-4">
                            <MdAlternateEmail className="h-6 w-6" />
                        </div>
                        <span className="mt-2 text-sm">E-mail</span>
                    </div>
                    <div className="flex flex-col items-center cursor-pointer" onClick={shareViaWhatsApp}>
                        <div className="bg-gray-200 rounded-full p-4">
                            <PiWhatsappLogoLight className="h-6 w-6" />
                        </div>
                        <span className="mt-2 text-sm">WhatsApp</span>
                    </div>
                    <div className="flex flex-col items-center cursor-pointer" onClick={shareViaTelegram}>
                        <div className="bg-gray-200 rounded-full p-4">
                            <PiTelegramLogoLight className="h-6 w-6" />
                        </div>
                        <span className="mt-2 text-sm">Telegram</span>
                    </div>
                    <div className="flex flex-col items-center cursor-pointer" onClick={shareViaX}>
                        <div className="bg-gray-200 rounded-full p-4">
                        <FaXTwitter className="h-6 w-6" />
                        </div>
                        <span className="mt-2 text-sm">X</span>
                    </div>
                    <div className="flex flex-col items-center cursor-pointer" onClick={shareViaFacebook}>
                        <div className="bg-gray-200 rounded-full p-4">
                            <FaFacebook className="h-6 w-6" />
                        </div>
                        <span className="mt-2 text-sm">Facebook</span>
                    </div>
                    <div className="flex flex-col items-center cursor-pointer" onClick={shareViaInstagram}>
                        <div className="bg-gray-200 rounded-full p-4">
                            <FaInstagram className="h-6 w-6" />
                        </div>
                        <span className="mt-2 text-sm">Instagram</span>
                    </div>
                </div>
                <div className="text-center mb-4 text-gray-500">Or share with link</div>
                <div className="flex items-center justify-between bg-gray-100 p-2 rounded-lg">
                    <input
                        type="text"
                        value={profileUrl}
                        className="bg-gray-100 text-gray-700 text-sm w-full focus:outline-none"
                        readOnly
                    />
                    <button className="bg-red-100 text-red-500 p-2 rounded-full ml-2" onClick={copyToClipboard}>
                        <IoShareSocialOutline className="h-6 w-6" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Share;
