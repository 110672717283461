import NavbarSection from "../components/Navbar";
import Location from '../assests/contactlocation.png';
import Phone from "../assests/contactphone.png";
import Email from "../assests/contactemail.png";
import btnArrow from '../assests/inputbtnarrow.png';
import FooterSection from "../components/Footer";
import { useState } from "react";
import api from '../services/api'; // Import your API methods
import Swal from "sweetalert2";

function ContactPage() {
    const [formData, setFormData] = useState({
        prenom: '',
        telephone: '',
        famille: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { prenom, telephone, famille, email, message } = formData;

        if (prenom && telephone && famille && email && message) {
            try {
                const response = await api.insertContactData(formData);
                if (response.success) {
                    Swal.fire({
                        title: "Succès",
                        html: "Formulaire soumis avec succès",
                        showClass: {
                            popup: `
                                animate__animated
                                animate__fadeInUp
                                animate__faster
                            `
                        },
                        hideClass: {
                            popup: `
                                animate__animated
                                animate__fadeOutDown
                                animate__faster
                            `
                        },
                        confirmButtonText: 'traduire',
                        customClass: {
                            confirmButton: 'custom-ok-button',
                            title: 'custom-title',
                            htmlContainer: 'custom-html'
                        }
                    });
                } else {
                    Swal.fire({
                        title: "Failed to store data in the database",
                        showClass: {
                            popup: `
                                animate__animated
                                animate__fadeInUp
                                animate__faster
                            `
                        },
                        hideClass: {
                            popup: `
                                animate__animated
                                animate__fadeOutDown
                                animate__faster
                            `
                        },
                        customClass: {
                            confirmButton: 'custom-failed-button',
                            title: 'custom-text'
                        }
                    });
                }
            } catch (error) {
                Swal.fire({
                    title: "Error during API call",
                    text: error.message,
                    showClass: {
                        popup: `
                            animate__animated
                            animate__fadeInUp
                            animate__faster
                        `
                    },
                    hideClass: {
                        popup: `
                            animate__animated
                            animate__fadeOutDown
                            animate__faster
                        `
                    },
                    customClass: {
                        confirmButton: 'custom-failed-button',
                        title: 'custom-text'
                    }
                });
            }
        } else {
            Swal.fire({
                title: "Please Fill All Information",
                showClass: {
                    popup: `
                        animate__animated
                        animate__fadeInUp
                        animate__faster
                    `
                },
                hideClass: {
                    popup: `
                        animate__animated
                        animate__fadeOutDown
                        animate__faster
                    `
                },
                customClass: {
                    confirmButton: 'custom-failed-button',
                    title: 'custom-text'
                }
            });
        }
    };

    return (
        <>
            <NavbarSection />
            <div className="  flex justify-center items-center translatable-text">
                 <h1  ClassName="    text-2xl lg:text-5xl font-bold text-green-900 ">Contactez-Nous</h1>
            </div>
            <div className="  flex justify-center items-center sm:my-8 my-2 mx-2 mt-6">
                <div className="  bg-[#E1F5C4] border border-lime-700 rounded-3xl shadow-lg p-6 lg:flex lg:w-4/5 mx-4">
                    <div className="  lg:w-1/2 p-4">
                    <div className="translatable-text">
                         <p  ClassName="    text-lg font-semibold text-gray-900 ">Des questions, des commentaires ou des suggestions ? Remplissez simplement le formulaire et nous vous contacterons sous peu.</p></div>
                        <ul className="  mt-6 space-y-4 text-gray-700">
                            <li className="  flex items-center space-x-2">
                                <img src={Location} alt="Location" width={18} />
                                <span>France</span>
                            </li>
                            
                            <li className="  flex items-center space-x-2">
                                <img src={Email} alt="Email" width={18} />
                                <span>support@btobjob.com</span>
                            </li>
                        </ul>
                    </div>
                    <div className="  lg:w-1/2 p-4">
                        <div className="  flex flex-col lg:flex-row gap-4">
                            <div className="  flex flex-col w-full lg:w-1/2 space-y-4">
                                <div>
                                    <label className="translatable-text  font-semibold text-gray-900">Prénom</label>
                                    <input id="prenom" value={formData.prenom} onChange={handleChange} placeholder="Prénom" className="  w-full p-2 border rounded-lg" type="text" />
                                </div>
                                <div>
                                    <label className="translatable-text  font-semibold text-gray-900">Téléphone</label>
                                    <input id="telephone" value={formData.telephone} onChange={handleChange} placeholder="Numéro" className="  w-full p-2 border rounded-lg" type="number" />
                                </div>
                            </div>
                            <div className="  flex flex-col w-full lg:w-1/2 space-y-4">
                                <div>
                                    <label className="translatable-text  font-semibold text-gray-900">Famille</label>
                                    <input id="famille" value={formData.famille} onChange={handleChange} placeholder="Famille" className="  w-full p-2 border rounded-lg" type="text" />
                                </div>
                                <div>
                                    <label className="translatable-text  font-semibold text-gray-900">E-mail</label>
                                    <input id="email" value={formData.email} onChange={handleChange} placeholder="hello@btobjob.com" className="  w-full p-2 border rounded-lg" type="email" />
                                </div>
                            </div>
                        </div>
                        <div className="  mt-6">
                            <label className="translatable-text  font-semibold text-gray-900">Comment pouvons-nous vous aider ?</label>
                            <textarea id="message" value={formData.message} onChange={handleChange} placeholder="Écrivez votre message" className="  w-full p-2 border rounded-lg h-32 resize-none" />
                        </div>
                        <button onClick={handleSubmit} className="  bg-gradient-to-r from-lime-400 to-lime-600 text-white font-semibold py-2 px-4 rounded-lg flex items-center mt-6">
                            Envoyer message
                            <img className="  ml-2" src={btnArrow} width={17} alt="arrow icon" />
                        </button>
                    </div>
                </div>
            </div>
            <FooterSection />
        </>
    );
}

export default ContactPage;
