import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { TiWorld } from "react-icons/ti";
import { MdOutlineCancel } from "react-icons/md";
import PublicRequestCompleted from './PublicRequestCompleted';

function PublicRequest({ toggleboolean, updateFormState }) {
  const [openForm3, setOpenForm3] = useState(false);
  const [localToggle, setLocalToggle] = useState(toggleboolean);
  const [fileInputs, setFileInputs] = useState([
    { ref: useRef(null), text: "+" },
    { ref: useRef(null), text: "+" },
    { ref: useRef(null), text: "+" }
  ]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [formData, setFormData] = useState({
    request: '',
    category: '',
    price: '',
    address: '',
    time: '',
  });

  useEffect(() => {
    setLocalToggle(toggleboolean);
  }, [toggleboolean]);

  useEffect(() => {
    if (localToggle) {
      console.log('Form has been toggled. Fetching recommendations...');
      // Fetch recommendations or perform any other necessary actions
    }
  }, [localToggle]);

  const handleButtonClick = (index) => {
    fileInputs[index].ref.current.click();
  };

  const handleFileChange = (index, event) => {
    const files = event.target.files;
    if (files.length > 0) {
      console.log('Selected file:', files[0]);
      setSelectedFiles([...selectedFiles, files[0]]);
      const updatedInputs = [...fileInputs];
      updatedInputs[index].text = "Élément sélectionné";
      setFileInputs(updatedInputs);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append('request', formData.request);
    data.append('category', formData.category);
    data.append('price', formData.price);
    data.append('address', formData.address);
    data.append('time', formData.time);
    selectedFiles.forEach((file, index) => {
      data.append(`file${index + 1}`, file);
    });

    try {
      const response = await axios.post('https://btob.btobjob.com/requests.php', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true, 
      });
      console.log(response.data);
      setOpenForm3(true);
      setLocalToggle(false);
      updateFormState(false);
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  const handleCancel = () => {
    setLocalToggle(!localToggle);
    updateFormState(!localToggle);
  };

  return (
    <>
      <div className={`${localToggle ? "flex" : "hidden"} items-center justify-center fixed inset-0 z-10 bg-black/50 z-[9999] `}>
        <div className="  max-w-[500px] mx-auto p-8 shadow-md rounded-xl bg-white mt-10">
          <div className="  flex items-center justify-between mb-4">
            <div className='flex items-center mr-5'>
              <TiWorld className="  w-6 h-6 " />
               <h2  ClassName="    text-xl font-semibold">Demande publique</h2>
            </div>
            <MdOutlineCancel onClick={handleCancel} className="  w-6 h-6 cursor-pointer" />
          </div>

          <form onSubmit={handleSubmit}>
            <textarea
              name="request"
              value={formData.request}
              onChange={handleChange}
              className="  w-full p-2 mb-4 border border-gray-300 rounded-md"
              placeholder="Quelle est votre demande"
            ></textarea>
           <p className='m-1 text-gray-500 text-sm'>Ajoutez votre image</p>
<div className="  flex justify-between mb-4 gap-2">
  {fileInputs.map((input, index) => (
    <div
      key={index}
      className="  w-1/3 border-2 border-dashed border-[#65A406] rounded-md p-4 flex justify-center items-center cursor-pointer"
      onClick={() => handleButtonClick(index)} // Make the entire div clickable
    >
      <button
        type="button"
        className="  text-[10px] text-[#65A406] font-bold"
      >
        {input.text}
      </button>
      <input
        type="file"
        ref={input.ref}
        style={{ display: 'none' }}
        onChange={(event) => handleFileChange(index, event)}
      />
    </div>
  ))}
</div>


            <div className="  mb-4">
              <p className='m-1 text-gray-500 text-sm'>Categorie</p>
              <select
                name="category"
                value={formData.category}
                onChange={handleChange}
                className="  w-full p-2 border text-sm border-gray-300 rounded-md"
              >
              <option value="">Sélectionnez une profession</option>
    <option value="Plomberie">Plomberie</option>
    <option value="Électricien">Électricien</option>
    <option value="Menuisier">Menuisier</option>
    <option value="Peintre">Peintre</option>
    <option value="Jardinier">Jardinier</option>
    <option value="Maçonnerie">Maçonnerie</option>
    <option value="Décoration">Décoration</option>
    <option value="Cuisiniste">Cuisiniste</option>
    <option value="Architecte">Architecte</option>
    <option value="Mécanicien">Mécanicien</option>
    <option value="Secrétaire">Secrétaire</option>
    <option value="Informaticien/Multimédia">Informaticien/Multimédia</option>
    <option value="Carrossier/Peintre">Carrossier/Peintre</option>
    <option value="Rénovation intérieure">Rénovation intérieure</option>
    <option value="Façadier">Façadier</option>
    <option value="Paysagiste/Jardinier">Paysagiste/Jardinier</option>
    <option value="Conducteur de pelle">Conducteur de pelle</option>
    <option value="Constructeur de piscines">Constructeur de piscines</option>
    <option value="Agent immobilier">Agent immobilier</option>
    <option value="Carreleur">Carreleur</option>
    <option value="Climatisation">Climatisation</option>
    <option value="Restaurateur">Restaurateur</option>
    <option value="Animateur">Animateur</option>
    <option value="Traducteur">Traducteur</option>
    <option value="Livreur">Livreur</option>
              </select>
            </div>

            <div className="  mb-4">
              <p className='m-1 text-gray-500 text-sm'>Ajoutez votre offre</p>
              <input
                type="text"
                name="price"
                value={formData.price}
                onChange={handleChange}
                className="  w-full p-2 border text-sm border-gray-300 rounded-md"
                placeholder="Prix"
              />
            </div>

            <div className="  mb-4">
              <p className='m-1 text-gray-500 text-sm'>Ajoutez votre adresse</p>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="  w-full p-2 border text-sm border-gray-300 rounded-md"
                placeholder="Sélectionnez l'adresse"
              />
            </div>

            {/* <div className="  mb-4">
              <p className='m-1 text-gray-500 text-sm'>Time</p>
              <input
                type="time"
                name="time"
                value={formData.time}
                onChange={handleChange}
                className="  w-full p-2 border text-sm border-gray-300 rounded-md"
              />
            </div> */}

            <button
              type="submit"
              className="  w-full p-2 bg-[#65A406] text-white font-semibold rounded-md hover:bg-green-600"
            >
             Confirmer
            </button>
          </form>
        </div>
      </div>
      <PublicRequestCompleted toggleboolean2={openForm3} />
    </>
  );
}

export default PublicRequest;
