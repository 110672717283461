import React, { useEffect } from 'react';
import axios from 'axios';

const ChromeNotifications = () => {
    useEffect(() => {
        if (Notification.permission !== "granted") {
            Notification.requestPermission();
        }

        const fetchNotifications = () => {
            axios.get('https://btob.btobjob.com/push-notifications.php', { withCredentials: true })
                .then((response) => {
                    if (response.data.success) {
                        const currentTime = new Date().getTime();
                        const newNotifications = response.data.notifications.filter(notification => {
                            const notificationTime = new Date(notification.timestamp).getTime();
                            const timeDifference = (currentTime - notificationTime) / 1000 / 60; // Difference in minutes
                            return notification.is_seen === "0" && timeDifference <= 5;
                        });

                        // Trigger notifications for new and unseen ones
                        triggerChromeNotifications(newNotifications);
                    } else {
                        console.log('Error fetching notifications:', response.data.message);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        };

        // Polling interval to fetch notifications every 1 minute
        const interval = setInterval(fetchNotifications, 60000);
        return () => clearInterval(interval);
    }, []);

    const triggerChromeNotifications = (newNotifications) => {
        if (Notification.permission === "granted") {
            newNotifications.forEach(notification => {
                const { notification_type, message } = notification;

                const options = {
                    body: message,
                    icon: 'https://btob.btobjob.com/assets/notification-icon.png', // Customize your icon path
                    tag: notification.id, // Unique tag to prevent duplicate notifications
                };

                const notificationTitle = notification_type.toUpperCase();
                new Notification(notificationTitle, options);
            });
        } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(permission => {
                if (permission === "granted") {
                    triggerChromeNotifications(newNotifications);
                }
            });
        } else {
            console.log("Notifications are blocked by the user.");
        }
    };

    return (
        <div>
            <h1>fregerg</h1>
            <h2>This is an H2 heading</h2>
        </div>
    );
};

export default ChromeNotifications;
