import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaRegMap, FaArrowLeft } from "react-icons/fa";
import { CiCalendar, CiStopwatch, CiWallet } from "react-icons/ci";
import { IoLayersOutline, IoBagOutline } from "react-icons/io5";
import UploadingCv from './UplaodingCv';
import api from '../../services/api';
import Share from '../Share';
import MessageModal from '../OfferPage/MessageModal';

const AdsDescription = () => {
  const { AdId } = useParams();
  const [Ad, setAd] = useState(null);
  const [openCV, setOpenCV] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAd = async () => {
      try {
        const AdData = await api.fetchAdById(AdId);
        setAd(AdData);
      } catch (error) {
        console.error('Error fetching Ad details:', error);
      }
    };
    if (AdId) {
      fetchAd();
    }
  }, [AdId]);

  const getUpdatedState = (state) => {
    setOpenCV(state);
  };

  const handleSendMessage = async (message) => {
    if (!Ad?.user_id) {
      console.error("User ID not found for the Ad. Cannot send message.");
      return;
    }

    if (!message || message.trim() === "") {
      console.error("Message content is empty. Please provide a valid message.");
      return;
    }

    try {
      const fullMessage = `Job Title: (${Ad.title}\n) ${message}`; // Add job title to the message

      const response = await api.sendMessagee({
        receiverId: Ad.user_id,
        message: fullMessage,
      });

      if (response.success) {
        alert("Message sent successfully!");
        setIsMessageModalOpen(false);
      } else {
        console.error("Failed to send message:", response.message);
        alert("Failed to send message. Please try again.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const viewProfile = () => {
    navigate(`/profile/specificUser?professional_id=${Ad.user_id}`);
  };

  if (!Ad) {
    return <div>Loading...</div>;
  }

  const AdDetails = [
    { icon: <CiCalendar size={24} color='#65A406' />, label: "Emploi affiché", value: Ad.created_at || "N/A" },
    { icon: <CiStopwatch size={24} color='#65A406' />, label: "Le travail expire dans", value: Ad.Ad_expires_on || "N/A" },
    { icon: <IoLayersOutline size={24} color='#65A406' />, label: "Le niveau d'emploi", value: Ad.entry_level_text || "N/A" },
    { icon: <CiWallet size={24} color='#65A406' />, label: "Expérience", value: Ad.salary || "N/A" },
    { icon: <IoBagOutline size={24} color='#65A406' />, label: "Éducation", value: Ad.experience_education || "N/A" },
  ];

  const decodeHtmlEntities = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };

  return (
    <div className="max-w-7xl mx-auto p-4 mt-10">
      <button
        onClick={() => navigate(-1)}
        className="mb-6 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
      >
        <FaArrowLeft className="inline mr-2" /> Back
      </button>
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-6 flex justify-between">
          <div className="flex items-center mb-4">
            <img className="md:w-16 md:h-16 w-10 h-10 object-cover rounded-full" src={Ad.ProfilePicture || "https://via.placeholder.com/64"} alt="company logo" />
            <div className="ml-4">
              <h2 className="md:text-2xl text-lg font-bold text-gray-800">
                {decodeHtmlEntities(Ad.title) || "N/A"}
              </h2>
              <p className="text-gray-600">
                at {Ad.location || "N/A"} <span className="bg-[#65A406] text-white text-xs py-1 px-2 rounded-sm">{Ad.Ad_type ? Ad.Ad_type.toUpperCase() : "N/A"}</span>
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            {!Ad.isOwner && (
              <button className="bg-[#65A406] text-white md:p-2 p-1 rounded-lg text-xs md:text-sm" onClick={() => setOpenCV(!openCV)}>Postuler</button>
            )}
            <button className="bg-[#65A406] text-white md:p-2 p-1 rounded-lg text-xs md:text-sm" onClick={() => setOpenShareModal(true)}>Partager</button>
            {!Ad.isOwner && (
              <button className="bg-blue-500 text-white md:p-2 p-1 rounded-lg text-xs md:text-sm" onClick={() => setIsMessageModalOpen(true)}>Envoyer un message</button>
            )}
            {/* <button
              onClick={viewProfile}
              className="bg-gray-500 text-white md:p-2 p-1 rounded-lg text-xs md:text-sm"
            >
              View Profile
            </button> */}
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 p-6">
          <div className="lg:col-span-2">
            <h3 className="text-xl font-semibold mb-4">Description de l'emploi</h3>
            <div dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(Ad.description) }} />
          </div>
          <div className="bg-gray-100 p-4 rounded-lg flex flex-col gap-y-4">
            <div className='flex items-center justify-between border p-1'>
              <div className='flex flex-col items-center md:border-r-2 p-4'>
                <p>Salarie <span>EURO</span></p>
                <p className="text-lg text-[#65A406]">{Ad.salary || "N/A"}</p>
                <p className='text-sm text-gray-400'>Salarie Annuel</p>
              </div>
              <div className='flex flex-col items-start p-2'>
                <FaRegMap size={24} color='#65A406' />
                <p className="font-semibold text-lg">Lieu de travail</p>
                <p className='text-sm text-gray-400'>{Ad.work_place || "N/A"}</p>
              </div>
            </div>
            <div className='flex items-start flex-col justify-between border p-1'>
              <p className='text-lg'>Aperçu du poste</p>
              <div className='flex flex-row flex-wrap gap-4 w-full'>
                {AdDetails.map((detail, index) => (
                  <div key={index} className='flex flex-col items-start md:p-2 p-4'>
                    {detail.icon}
                    <p className='text-sm text-gray-400'>{detail.value}</p>
                    <p className="font-semibold text-base">{detail.label}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <UploadingCv boolean={openCV} returnState={getUpdatedState} AdId={AdId} />

      {openShareModal && (
        <Share
          profileUrl={window.location.href}
          closeModal={() => setOpenShareModal(false)}
        />
      )}

      {isMessageModalOpen && (
        <MessageModal
          isOpen={isMessageModalOpen}
          onClose={() => setIsMessageModalOpen(false)}
          onSend={handleSendMessage}
        />
      )}
    </div>
  );
};

export default AdsDescription;
