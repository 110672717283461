import React, { useState, useEffect } from "react";
import ProfileContent from "../../assests/profileContent.png";
import UserProfile from "../../assests/profileuser.png";
import { MdOutlineEmail, MdCurrencyFranc, MdCancel } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import Star from "../../assests/Star.png";
import { IoMdContacts } from "react-icons/io";
import { BiMessageRoundedDots } from "react-icons/bi";
import { IoBriefcaseOutline } from "react-icons/io5";
import Photo1 from "../../assests/photos1.png";
import Photo2 from "../../assests/photos2.png";
import Photo3 from "../../assests/photos3.png";
import Photo4 from "../../assests/photos4.png";
import { FaEnvelope } from "react-icons/fa";
import SpecToggler from "../SpecToggler";
import { FaHammer } from "react-icons/fa6";
import { RiFlag2Fill } from "react-icons/ri";
import MAP from "../../assests/map.png";
import PostingPicture from "../../assests/postingpicture.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Rate2 from "../ProfileView/Rate2";
import ProfileShare from "../ProfileView/ProfileShare";
import api from "../../services/api"; // Assuming you have an API service set up
import MessageModal from './MessageModal';
import { MapContainer, TileLayer, Marker, Popup, Circle } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import ReviewsModal from './ReviewsModal';
import PostShare from "../RequestPage/SharePost";
import { FaShareAlt } from "react-icons/fa";
import { AiOutlineShake } from "react-icons/ai";
import axios from "axios";
const MainProfile = () => {
  const [profileData, setProfileData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openShare, setOpenShare] = useState(false);
  const [openRate, setOpenRate] = useState(false);
  const [isOpenService, setIsOpenService] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isReviewsModalOpen, setIsReviewsModalOpen] = useState(false);
  const [liked, setLiked] = useState(false);
  const [posts, setPosts] = useState([]);
  const [likesStatus, setLikesStatus] = useState({});
  const openReviewsModal = () => setIsReviewsModalOpen(true);
  const closeReviewsModal = () => setIsReviewsModalOpen(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rate, setRate] = useState({ minPrice: 0, maxPrice: 0 });
  const [photos, setPhotos] = useState([]);
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [isProfessional, setIsProfessional] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [subscriptionExists, setSubscriptionExists] = useState(false);
  const [likeCounts, setLikeCounts] = useState({});
  const [commentsModalOpen, setCommentsModalOpen] = useState(false);  
  const [commentCounts, setCommentCounts] = useState({});
  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState({});
  const [selectedPostComments, setSelectedPostComments] = useState([]);  

  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState({});
  const [selectedPostId, setSelectedPostId] = useState(null);
  const initialZoomLevel = 7;
  const [selectedImage, setSelectedImage] = useState(null);
  const reviews = [
    { author: 'User1', text: 'Great service!' },
    { author: 'User2', text: 'Very professional.' },
    { author: 'User3', text: 'Highly recommended!' },
    // Add more reviews as needed
  ];
const openCommentsModal = (postId) => {
      setSelectedPostId(postId);
      fetchComments(postId); // Fetch comments when modal opens
      setIsCommentsModalOpen(true);
    };
  
    // Close Comments Modal
    const closeCommentsModal = () => {
      setIsCommentsModalOpen(false);
      setSelectedPostId(null);
      setSelectedPostComments([]);
    };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const professionalId = queryParams.get("professional_id");
  const navigate = useNavigate();
  const [ratingData, setRatingData] = useState({ avg_rating: 0, total_reviews: 0 });
  const handleLike = async (postId) => {
    try {
      const response = await axios.post('https://btob.btobjob.com/like-post.php', { postId }, { withCredentials: true });
      setLikesStatus(prevStatus => ({
        ...prevStatus,
        [postId]: response.data.liked
      }));
    } catch (error) {
      console.error('Error liking the post:', error);
    }
  };
  const openLightbox = (imageSrc) => {
    setSelectedImage(imageSrc);
    setLightboxOpen(true);
  };
  
  const closeLightbox = () => {
    setLightboxOpen(false);
    setSelectedImage(null);
  };  
  const toggleMenu = (postId) => {
    setIsMenuOpen((prev) => ({ ...prev, [postId]: !prev[postId] }));
  };

  const fetchComments = async (postId) => {
    try {
      const response = await axios.post(
        'https://btob.btobjob.com/fetch-post-comments.php',
        { postId },
        { withCredentials: true }
      );
      if (response.status === 200) {
        setComments((prevComments) => ({
          ...prevComments,
          [postId]: response.data.comments,
        }));
        setSelectedPostComments(response.data.comments); // Set comments for selected post
  
        // Return the number of comments for the post
        return response.data.comments.length;
      } else {
        console.error('Error fetching comments:', response.data.error);
        return 0;
      }
    } catch (error) {
      console.error('Error fetching comments:', error);
      return 0;
    }
  };
  const handleAddComment = async () => {
    // Ensure there's no empty or invalid input
    if (!newComment.trim()) return;
  
    try {
      // Send only the postId and commentText, no event or other complex object
      const response = await axios.post(
        'https://btob.btobjob.com/add-post-comment.php',
        { postId: selectedPostId, commentText: newComment.trim() },
        { withCredentials: true }
      );
  
      if (response.data.success) {
        setNewComment('');  // Reset comment input
        fetchComments(selectedPostId);  // Fetch updated comments
      } else {
        console.error('Error adding comment:', response.data.error);
      }
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };
    
  useEffect(() => {
    const getSubscription = async () => {
      try {
        const data = await api.getSubscriptionspecific(professionalId); // Pass professionalId to the API call

        console.log('Subscription data:', data);

        // Ensure the value is a boolean
        setSubscriptionExists(!!data.subscription_exists);
        setIsProfessional(!!data.subscription_exists);
      } catch (error) {
        console.error('Error fetching subscription status:', error);
      }
    };

    if (professionalId) {
      getSubscription(); // Call the function only if professional_id is available
    }
  }, [professionalId]);
  useEffect(() => {
    if (!professionalId) return; // Avoid fetching if the ID is not available

    const fetchImages = async () => {
      try {
        // Fetch profile and cover images using professional ID
        const profileData = await api.fetchProfileImagebyid(professionalId);
        const coverData = await api.fetchCoverImagebyid(professionalId);

        // Set the image previews in the state
        setProfileImagePreview(profileData.imageUrl);
        setCoverImagePreview(coverData.imageUrl);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, [professionalId]);
  useEffect(() => {
    const fetchPhotos = async () => {
        try {
            // Check if professionalId is available
            if (!professionalId) {
                throw new Error('Professional ID is missing');
            }

            const response = await axios.get(`https://btob.btobjob.com/get_photos_specific.php?professional_id=${professionalId}`, { withCredentials: true });
            
            if (Array.isArray(response.data)) {
                setPhotos(response.data.slice(0, 4)); // Limit to 4 photos
            } else {
                throw new Error('Unexpected response format');
            }
        } catch (error) {
            console.error("Error fetching photos:", error);
            setError("Error fetching photos");
        } finally {
            setLoading(false);
        }
    };

    fetchPhotos();
}, [professionalId]); // Depend on professionalId
useEffect(() => {
  const fetchRates = async () => {
    if (!professionalId) {
      console.error('No professional_id provided in the URL');
      setError('No professional_id provided');
      setLoading(false);
      return;
    }

    try {
      // Append professional_id to the API request URL
      const response = await axios.get(`https://btob.btobjob.com/fetchRates_speecific.php?professional_id=${professionalId}`, { withCredentials: true });
      setRate(response.data);
    } catch (error) {
      console.error('Error fetching rates:', error);
      setError('Error fetching rates');
    } finally {
      setLoading(false);
    }
  };

  fetchRates();
}, [professionalId]);
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await api.fetchProfileById(professionalId);
        setProfileData(response);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (professionalId) {
      fetchProfileData();
    } else {
      setIsLoading(false);
    }
  }, [professionalId]);
  const toggleShare = () => {
    setOpenShare((prevState) => !prevState);
  };
  const checkLikeStatus = async (postId) => {
    try {
      const response = await axios.post('https://btob.btobjob.com/check-like.php', { postId }, { withCredentials: true });
      return response.data.liked;
    } catch (error) {
      console.error('Error checking like status:', error);
      return false;
    }
  };
  const fetchSpecificPosts = async (professionalId) => {
    try {
      const postsData = await api.fetchSpecificPosts(professionalId);
      setPosts(postsData);

      // Check like status for each post
      const likesPromises = postsData.map(post => checkLikeStatus(post.id));
      const likesResults = await Promise.all(likesPromises);
      const newLikeCounts = {};
      const likesStatus = {};
      postsData.forEach((post, index) => {
        likesStatus[post.id] = likesResults[index];
      });
      setLikesStatus(likesStatus);
    } catch (error) {
      console.error('Error fetchin+ posts:', error);
    }
  };
  const navigateToReviews = () => {
    const reviewsUrl = `https://btob.btobjob.com/reviews.php?professional_id=${professionalId}`;
    window.location.href = reviewsUrl;
};
const navigateTopinions = () => {
  const reviewsUrl = `https://btob.btobjob.com/opinions.php?professional_id=${professionalId}`;
  window.location.href = reviewsUrl;
};
useEffect(() => {
  const fetchRatings = async () => {
    if (!professionalId) return; // If professionalId is not available, exit early

    try {
      const response = await axios.get('https://btob.btobjob.com/get-user-reviews-specific.php', {
        params: { professional_id: professionalId }, // Pass professionalId as a query parameter
        withCredentials: true
      });
      const data = response.data;

      // Ensure avg_rating is a number and handle possible undefined/null
      const avgRating = data.avg_rating !== null && data.avg_rating !== undefined
        ? parseFloat(data.avg_rating)
        : 0;

      setRatingData({
        avg_rating: avgRating,
        total_reviews: data.total_reviews || 0
      });
    } catch (error) {
      console.error('Error fetching ratings:', error);
      setError('Error fetching ratings');
    } finally {
      setLoading(false);
    }
  };

  fetchRatings();
}, [professionalId]); // Include professionalId in the dependency array

const avgRating = ratingData.avg_rating ? ratingData.avg_rating.toFixed(1) : '0.0';
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const professionalId = urlParams.get('professional_id');
    fetchSpecificPosts(professionalId);
  }, []);
  const toggleRate = () => {
    setOpenRate((prevState) => !prevState);
    setTimeout(() => {
      setOpenRate(false);
    }, 20000);
  };
  const isImageFile = (url) => {
    return /\.(jpg|jpeg|png|gif)$/i.test(url);
};
  const NavigateToeditprofile = () => {
    navigate("/profile/editprofile");
  };

  const NavigateTochat = () => {
    setIsMessageModalOpen(true);
  };

  const NavigateToallphotos = () => {
    navigate(`/profile/ProfilePhotos?professional_id=${professionalId}`);
  };

  const toggleDropdownForService = () => {
    setIsOpenService((prevState) => !prevState);
  };


  const handleSendMessage = async (message) => {
    try {
      const response = await api.sendMessagee({
        receiverId: professionalId,
        message: message
      });
      console.log("Message sent response:", response);
      navigate(`/profile/chat`);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };
  const navigateToSpecificDoc = () => {
    navigate(`/profile/SpecificDoc?professional_id=${professionalId}`);
  };
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!profileData) {
    return <div>Profile data not found.</div>;
  }
const openPostShareModal = (postId) => {
    setSelectedPostId(postId);
    setIsShareModalOpen(true);
  };

  const closePostShareModal = () => {
    setIsShareModalOpen(false);
  };

  const navigateToPostDetails = (postId) => {
    navigate(`/profile/posts/${postId}`); // Navigate to the post details page with postId in the URL
  };
  return (
    <>
      <div className="  mx-auto md:max-w-3xl lg:max-w-4xl">
        <div>
          <img  src={coverImagePreview || 'default-cover.jpg'} className="  w-full object-cover h-48" />
        </div>
        <div className="  flex flex-col bg-white pb-6 relative">
          <div className="  flex justify-between py-1 px-1 bg-white relative mb-4">
            <div className="  flex relative ">
              <img
        className="  w-24 h-24 object-cover rounded-full border-white"
                src={profileImagePreview || 'default-profile.jpg'}
                alt="profile"
                
              />
              <div className="  flex flex-col items-start">
                <span className="  user-name">{profileData ? profileData.Name: 'Loading...'}</span>
                <span className="  user-email">@{profileData.username}</span>
              </div>
            </div>
            <div className="  flex flex-col absolute right-2">
              <div className="  flex flex-row items-center justify-center gap-1">
                <button
                  onClick={toggleShare}
                  className="  w-full cursor-pointer text-[10px] bg-white rounded-xl border-2 border-[#65A406] text-[#65A406] p-2"
                >
                  Partager
                </button>
                {/* <button onClick={NavigateToeditprofile} className="  sm:w-full cursor-pointer text-[10px] w-[140px]  bg-white rounded-xl border-2 border-[#65A406] text-[#65A406]">
                  Editer le profil
                </button> */}
              </div>

              <SpecToggler professionalId={professionalId} />

            </div>
            <div></div>
          </div>

          <div className="  fle flex-col ml-10">
            <div className="  flex  items-center text-xs text-[#004225] sm:text-[15px] font-normal leading-5">
              <CiLocationOn className="  location-email-icon" />
              <p>{profileData.Address}, {profileData.city} , {profileData.zip}</p>
            </div>
            <div className="  flex items-center text-xs text-[#004225] sm:text-[15px] font-normal leading-5">
              <AiOutlineShake className="  location-email-icon" />        <p>{profileData.Phone}</p>
            </div>
            <div className="  flex  items-center text-xs text-[#004225] sm:text-[15px] font-normal leading-5">
              <MdOutlineEmail className="  location-email-icon" />
              <p>{profileData.Email}</p>
            </div>
            {subscriptionExists && (
            <div className="  flex flex-col items-start text-xs text-[#004225] sm:text-[15px] font-normal leading-5 ms-10 mt-3">
   <h6  ClassName="    mb-1">Bio:</h6> 
   <p  ClassName="    font-bold">
    {profileData.bio}
  </p>
</div> )}

          </div>
      
          <div className="  absolute"></div>
        </div>
        {/* Zain req */}
        {/* <div className="  bg-white border-t-2">
        <ul className="  purple-list">
          <li className="  purple-listing">Aperçu</li>
          <li className="  purple-listing">Demandes</li>
          <li className="  purple-listing">Activité</li>
          <li className="  purple-listing">Avis</li>
          <li className="  purple-listing">Des photos</li>
        </ul>
      </div> */}
        {/* main div */}
        <div className="  flex flex-col-reverse sm:flex-row justify-between mt-5">
        {subscriptionExists && (
        <div className="  grid grid-cols-1 sm:flex sm:flex-col gap-2">
            <div
              className="  flex flex-col items-center justify-center bg-white w-full sm:w-[150px] rounded-xl sm:mt-0 mt-3 cursor-pointer p-3">
               <p  ClassName="    flex items-center gap-1">
                Siret: {profileData.kbis}
              </p>
              </div>
              </div>)}
            </div>
            <div className="  flex flex-col-reverse sm:flex-row justify-between mt-5">
          <div className="  grid grid-cols-2 sm:flex sm:flex-col gap-2">
            <div
              className="  flex flex-col items-center justify-center bg-white w-full sm:w-[150px] rounded-xl sm:mt-0 mt-3 cursor-pointer"
              onClick={navigateToReviews}
            >
               <p  ClassName="    flex items-center gap-1">
                {avgRating} <img src={Star} className="  w-3 h-3" alt="Star" />
              </p>
              
            </div>
            {subscriptionExists && (
            <div className="flex flex-col justify-center bg-white w-full sm:w-[150px] rounded-xl mt-3 px-2 py-2">
  <div className="flex justify-between">
    <p className="photos-para">Documents</p>
    <p onClick={navigateToSpecificDoc} className="w-full text-center text-lime-500 rounded-md cursor-pointer">
      Voir
    </p>
  </div>
  
  {loading ? (
    <p>Loading...</p>
  ) : error ? (
    <p>{error}</p>
  ) : (
    <div className="grid grid-cols-2 gap-2">
      {profileData.insurance ? (
        isImageFile(profileData.insurance) ? (
          <img
            src={`https://btob.btobjob.com/${profileData.insurance}`}
            width={70}
            className="w-full object-contain cursor-pointer"
            alt="User Insurance"
            onClick={() => openLightbox(`https://btob.btobjob.com/${profileData.insurance}`)}
            onError={(e) => (e.target.outerHTML = '<p class="text-red-500 text-center">Image not found</p>')}
          />
        ) : (
          <div className="w-full h-48 flex items-center justify-center bg-gray-200">
            <p>Doc</p>
          </div>
        )
      ) : (
        <p className="text-red-500">No insurance document available</p>
      )}

      {profileData.civil_liability ? (
        isImageFile(profileData.civil_liability) ? (
          <img
            src={`https://btob.btobjob.com/${profileData.civil_liability}`}
            width={70}
            className="w-full object-contain cursor-pointer"
            alt="User Civil Liability"
            onClick={() => openLightbox(`https://btob.btobjob.com/${profileData.civil_liability}`)}
            onError={(e) => (e.target.outerHTML = '<p class="text-red-500 text-center">Image not found</p>')}
          />
        ) : (
          <div className="w-full h-48 flex items-center justify-center bg-gray-200">
            <p>Doc</p>
          </div>
        )
      ) : (
        <p className="text-red-500">No civil liability document available</p>
      )}
    </div>
  )}
</div>)}
             {/* <div
              className=" py-2 flex flex-col items-center justify-center bg-white w-full sm:w-[150px] rounded-xl sm:mt-0 mt-3 cursor-pointer"
              onClick={navigateTopinions}
            >
            
               <p  ClassName="    div1-para-reviews">All Opinions</p>
            </div> */}
            
            {/* 2nd */}
            {/* <div className="  flex flex-col  justify-center bg-white w-full sm:w-[150px] rounded-xl mt-3 pl-4">
              <div className="  flex items-center text-[#65A406] text-[10px] sm:text-xs gap-2 py-1">
                <IoMdContacts className="  w-5 h-5" />
                <div className="  flex flex-col text-black">
                   <p  ClassName="    font-semibold">38,47,154</p>
                  <p>Connexions</p>
                </div>
              </div>
              <div className="  flex items-center text-[#65A406] text-[10px] sm:text-xs gap-2 py-1">
                <BiMessageRoundedDots className="  w-5 h-5" />
                <div className="  flex flex-col text-black">
                   <p  ClassName="    font-semibold">70%</p>
                  <p> Temps de réponse</p>
                </div>
              </div>
              <div className="  flex items-center text-[#65A406] text-[10px] sm:text-xs gap-2 py-1">
                <IoBriefcaseOutline className="  w-5 h-5" />
                <div className="  flex flex-col text-black">
                   <p  ClassName="    font-semibold">3324</p>
                  <p>Emploi en direct</p>
                </div>
              </div>
            </div> */}
            {/* 3rd */}
            {subscriptionExists && (
            <div className="  flex flex-col justify-center bg-white w-full sm:w-[150px] rounded-xl mt-3 px-2 py-2">
            <div className="  flex justify-between">
                 <p  ClassName="    photos-para">Photos</p>
                 <p className="text-lime-500 cursor-pointer" onClick={NavigateToallphotos}>Voir</p>

            </div>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <div className="  grid grid-cols-2 gap-2">
                    {photos.map((photo, index) => (
                        <img
                        key={index}
                        src={`https://btob.btobjob.com/${photo.photo_url}`}
                        width={70}
                        className="w-full object-contain"
                        alt={`User Photo ${index + 1}`}
                        onError={(e) => (e.target.outerHTML = '<p class="text-red-500 text-center">Image not found</p>')}
                      />  
                    ))}
                </div>
            )}
        </div>   )}
            {/* 4th */}
            <div className="  flex flex-col gap-2 justify-center items-start bg-white px-2 py-2 rounded-xl mt-3 w-full sm:w-[150px]">
            <div>
      {/* Your profile component layout */}
      <button onClick={NavigateTochat} className="bg-lime-500 text-white p-1 rounded font-bold">Send Message</button>

      <MessageModal
        isOpen={isMessageModalOpen}
        onClose={() => setIsMessageModalOpen(false)}
        onSend={handleSendMessage}
      />
    </div>
    {subscriptionExists && (
    <div className="  flex items-center justify-center gap-2 text-md">
                <MdCurrencyFranc className="  " size={20} />
                 <p  ClassName="    div2-para">   taux horaire: {rate.hourlyRate}</p>
              </div>
 )}
             
              {/* <div className={`${user?"flex":"hidden"} cursor-pointer`} onClick={toggleRate}>
                <div className="  flex items-center justify-center gap-2 text-md">
                  <FaEdit className="  " />
                   <p  ClassName="    div2-para">Modifier le tarif</p>
                </div>
              </div> */}


              <div className="  flex items-center "></div>
              <div className="  flex items-center"></div>
            </div>
          </div>
          {/* right 👇🏻 */}
          <div className="  flex flex-col gap-2 w-full sm:ml-4">
            <div className="  flex items-center justify-center w-full  py-2 rounded-xl">
          
            </div>
            <div className="  w-full h-80">
            <MapContainer
  center={[profileData.latitude, profileData.longitude]}
  zoom={initialZoomLevel}
  style={{ height: "100%", width: "100%" }}
  dragging={false}            // Disable dragging
  touchZoom={false}           // Disable zoom on touch devices
  scrollWheelZoom={false}     // Disable zooming using scroll wheel
  doubleClickZoom={false}     // Disable zooming on double-click
  keyboard={false}            // Disable keyboard navigation
>
  <TileLayer
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  />
  <Marker position={[profileData.latitude, profileData.longitude]}>
    <Popup>
      {profileData.name} <br /> {profileData.email}
    </Popup>
  </Marker>
  
  {/* Circle component to display radius */}
  {profileData.radius && (
    <Circle
      center={[profileData.latitude, profileData.longitude]}
      radius={profileData.radius} // Radius in meters
      pathOptions={{ color: '#298ade', fillColor: '#298ade', fillOpacity: 0.2 }}
    />
  )}
</MapContainer>
      </div>
      <div className="  flex flex-col gap-1">
      {posts.map((post) => (
    <div
      key={post.id}
      className="bg-white p-8 shadow-md rounded-lg w-full mb-4 cursor-pointer"
      onClick={() => navigateToPostDetails(post.id)}
    >
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-2">
          <img src={post.profile_picture} alt="User Avatar" className="w-8 h-8 rounded-full" />
          <div>
            <p className="text-gray-800 font-semibold">{post.name}</p>
            <p className="text-gray-500 text-sm">{post.created_at}</p>
          </div>
        </div>
      </div>

      {/* Post Content */}
      <div className="mb-4">
        <p className="text-gray-800">{post.text}</p>
      </div>

      {/* Media Content */}
      {post.media.length > 0 && (
        <div className={`mb-4 ${post.media.length > 1 ? 'grid grid-cols-2 gap-2' : ''}`}>
          {post.media.map((file, index) => (
            <div key={index} className="relative">
              {file.type.startsWith('image/') ? (
                <img
                  src={file.url}
                  alt={`Post Media ${index + 1}`}
                  className="w-full h-48 object-cover rounded-md mb-2 cursor-pointer"
                  onClick={() => openLightbox(file.url)}
                />
              ) : file.type.startsWith('video/') ? (
                <video
                  controls
                  className="w-full h-48 object-cover rounded-md mb-2"
                >
                  <source src={file.url} type={file.type} />
                  Your browser does not support the video tag.
                </video>
              ) : null}
            </div>
          ))}
        </div>
      )}

    {/* Like, Comment, and Share Buttons */}
    <div className="flex items-center justify-between text-gray-500">
      <button
        className={`flex items-center gap-2 px-2 hover:bg-gray-50 rounded-full p-1 ${likesStatus[post.id] ? 'text-red-600' : 'text-gray-500'}`}
        onClick={(e) => { e.stopPropagation(); handleLike(post.id); }}
      >
        <svg className={`w-5 h-5 ${likesStatus[post.id] ? 'fill-current text-red-600' : 'fill-gray-400'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M12 21.35l-1.45-1.32C6.11 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-4.11 6.86-8.55 11.54L12 21.35z" />
        </svg>
        {likesStatus[post.id] ? 'Liked' : 'Like'} ({likeCounts[post.id] || 0})
      </button>

      <button onClick={(e) => { e.stopPropagation(); openCommentsModal(post.id); }}>
        Comments ({commentCounts[post.id] || 0})
      </button>

      <button
        className="text-gray-500 hover:text-blue-500 flex items-center gap-1"
        onClick={(e) => {
          e.stopPropagation(); // Prevent post detail navigation on button click
          openPostShareModal(post.id); // Open share modal with post URL
        }}
      >
        <FaShareAlt />
        <span>Share</span>
      </button>
    </div>
  </div>
))}
 </div> </div> </div> </div>
{isCommentsModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-lg max-w-lg w-full relative">
            {/* Close Button */}
            <button onClick={closeCommentsModal} className="absolute top-2 right-2 text-gray-500">
              &times;
            </button>

            <h2>Comments for Post ID: {selectedPostId}</h2>

            {/* Display Comments */}
            {comments[selectedPostId]?.length > 0 ? (
              comments[selectedPostId].map((comment, index) => (
                <div key={index} className="mt-2 border-b pb-2">
                  <p><strong>{comment.user_name}</strong>: {comment.comment_text}</p>
                  <p className="text-gray-500 text-sm">{new Date(comment.created_at).toLocaleString()}</p>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No comments yet.</p>
            )}

            {/* Add New Comment */}
            <div className="mt-4">
              <input
                type="text"
                placeholder="Add a comment..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                className="border p-2 rounded w-full"
              />
              <button onClick={handleAddComment} className="mt-2 px-4 py-2 bg-blue-500 text-white rounded">
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Share Modal */}
      {isShareModalOpen && (
        <PostShare
          isOpen={isShareModalOpen}
          onClose={closePostShareModal}
          shareUrl={`https://btob.btobjob.com/profile/posts/${selectedPostId}`}
        />
      )}

      {/* Profile Share and Rate Modals */}
      <ProfileShare boolean={openShare} />
      <Rate2 boolean={openRate} />
    </>
  );
};
export default MainProfile;
