import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const EditOffer = () => {
  const { id } = useParams();
  const [offer, setOffer] = useState({ description: "", category: "", image: "" });
  const [selectedImage, setSelectedImage] = useState(null); // State to hold new image file
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchOffer();
  }, []);

  const fetchOffer = async () => {
    try {
      const response = await axios.get(`https://btob.btobjob.com/fetch_offer.php?id=${id}`, {
        withCredentials: true,
      });
      setOffer(response.data.offer);
    } catch (error) {
      console.error("Error fetching offer:", error);
      setError("Failed to load offer details.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOffer((prevOffer) => ({ ...prevOffer, [name]: value }));
  };

  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("id", id);
    formData.append("description", offer.description);
    formData.append("category", offer.category);
    if (selectedImage) {
      formData.append("image", selectedImage); // Add the new image file if selected
    }

    try {
      await axios.post("https://btob.btobjob.com/edit_offer.php", formData, {
        withCredentials: true,
        headers: { "Content-Type": "multipart/form-data" },
      });
      navigate("/profile/OfferHistory"); // Redirect back to offer history after editing
    } catch (error) {
      console.error("Error updating offer:", error);
      setError("Failed to update offer.");
    }
  };

  return (
    <div className="max-w-md mx-auto p-8 bg-white shadow-lg rounded-lg mt-10">
      <h1 className="text-2xl font-bold mb-4">Edit Offer</h1>
      {error && <p className="text-red-500">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">Category</label>
          <input
            type="text"
            name="category"
            value={offer.category}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Description</label>
          <textarea
            name="description"
            value={offer.description}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Current Image</label>
          {offer.image && <img src={offer.image} alt="Current Offer" className="w-32 h-32 object-cover" />}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Change Image</label>
          <input type="file" onChange={handleImageChange} className="w-full px-3 py-2 border rounded-md" />
        </div>
        <button type="submit" className="bg-lime-500 text-white px-4 py-2 rounded-md">
          Update Offer
        </button>
      </form>
    </div>
  );
};

export default EditOffer;
