import React, { useEffect, useState } from 'react';
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import api from '../../services/api';

const ProfileDoc = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [profileData, setProfileData] = useState(null);

    // Helper function to check if the document is an image based on file extension
    const isImageFile = (url) => {
        return /\.(jpg|jpeg|png|gif)$/i.test(url);
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await api.fetchProfile();
                if (response) {
                    setProfileData(response);
                } else {
                    setError("Profile data not found");
                }
            } catch (error) {
                console.error("Error fetching profile data:", error);
                setError("Failed to fetch profile data");
            } finally {
                setLoading(false);
            }
        };

        fetchProfileData();
    }, []);

    const NavigateToBack = () => {
        navigate(-1);
    };

    const navigateToUpdateDoc = () => {
        window.location.href = "https://btob.btobjob.com/update-doc.php";
    };

    // Function to trigger download for the given document
    const downloadDocument = (url, filename) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="mx-auto md:max-w-3xl lg:max-w-4xl bg-white rounded-lg md:mt-16">
            <div className='flex flex-col'>
                <div className='p-6 border-b-2 flex flex-col'>
                    <div onClick={NavigateToBack} className='flex items-center cursor-pointer'>
                        <FaArrowLeft className='h-6 w-6 pr-2' />
                        <p className="text-xl font-semibold">Toutes les photos</p>
                    </div>
                    <p className='text-xs'>Voici quelques photos récentes</p>
                </div>

                {loading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    profileData && (
                        <div className="grid grid-cols-2 gap-4 p-4 border rounded-md">
                            {/* Insurance Document */}
                            <div className="flex flex-col items-center">
                                <p className="text-lg font-semibold mb-2">User Insurance</p>
                                {profileData.insurance ? (
                                    <>
                                        {isImageFile(profileData.insurance) ? (
                                            <img
                                                src={`https://btob.btobjob.com/${profileData.insurance}`}
                                                className="w-full object-contain cursor-pointer"
                                                alt="User Insurance"
                                            />
                                        ) : (
                                            <div className="w-full h-48 flex items-center justify-center bg-gray-200">
                                                <p>Document</p>
                                            </div>
                                        )}
                                        <button
                                            onClick={() => downloadDocument(`https://btob.btobjob.com/${profileData.insurance}`, 'insurance')}
                                            className="mt-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                        >
                                            Download Insurance
                                        </button>
                                    </>
                                ) : (
                                    <p className="text-red-500">No insurance document available</p>
                                )}
                            </div>

                            {/* Civil Liability Document */}
                            <div className="flex flex-col items-center">
                                <p className="text-lg font-semibold mb-2">User Civil Liability</p>
                                {profileData.civil_liability ? (
                                    <>
                                        {isImageFile(profileData.civil_liability) ? (
                                            <img
                                                src={`https://btob.btobjob.com/${profileData.civil_liability}`}
                                                className="w-full object-contain cursor-pointer"
                                                alt="User Civil Liability"
                                            />
                                        ) : (
                                            <div className="w-full h-48 flex items-center justify-center bg-gray-200">
                                                <p>Document</p>
                                            </div>
                                        )}
                                        <button
                                            onClick={() => downloadDocument(`https://btob.btobjob.com/${profileData.civil_liability}`, 'civil_liability')}
                                            className="mt-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                        >
                                            Download Liability
                                        </button>
                                    </>
                                ) : (
                                    <p className="text-red-500">No civil liability document available</p>
                                )}
                            </div>

                            {/* KBIS Document */}
                            <div className="flex flex-col items-center">
                                <p className="text-lg font-semibold mb-2">User KBIS</p>
                                {profileData.kbis_file ? (
                                    <>
                                        {isImageFile(profileData.kbis_file) ? (
                                            <img
                                                src={`https://btob.btobjob.com/${profileData.kbis_file}`}
                                                className="w-full object-contain cursor-pointer"
                                                alt="User KBIS"
                                            />
                                        ) : (
                                            <div className="w-full h-48 flex items-center justify-center bg-gray-200">
                                                <p>Document</p>
                                            </div>
                                        )}
                                        <button
                                            onClick={() => downloadDocument(`https://btob.btobjob.com/${profileData.kbis_file}`, 'kbis')}
                                            className="mt-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                        >
                                            Download KBIS
                                        </button>
                                    </>
                                ) : (
                                    <p className="text-red-500">No KBIS document available</p>
                                )}
                            </div>
                        </div>
                    )
                )}

                <div className='p-6'>
                    <button
                        onClick={navigateToUpdateDoc}
                        className='bg-lime-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                    >
                        Mettre à jour les documents
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProfileDoc;
