import React from 'react'
import User1 from '../assests/people1.png'
import User2 from '../assests/people2.png'
import User3 from '../assests/people3.png'
import User4 from '../assests/people4.png'
import User5 from '../assests/people5.png'
import User6 from '../assests/people6.png'
import User7 from '../assests/people7.png'
import User8 from '../assests/people8.png'
import User9 from '../assests/people9.png'
import User10 from '../assests/people10.png'
import User11 from '../assests/people11.png'
import User12 from '../assests/people12.png'
const Users = () => {
  return (
    <div className="  ml-10 mt-10 bg-[#FFF]">
        <div className="  grid grid-cols-4 md:grid-cols-6 lg:grid-cols-12">
          <img src={User1} width={50} height={50} />
          <img className="  users-image-setting" src={User2} width={50} />
          <img src={User3} width={50} height={50} />
          <img className="  users-image-setting" src={User4} width={50} />
          <img src={User5} width={50} height={50} />
          <img className="  users-image-setting" src={User6} width={50} />
          <img src={User7} width={50} height={50} />
          <img className="  users-image-setting" src={User8} width={50} />
          <img src={User9} width={50} height={50} />
          <img className="  users-image-setting" src={User10} width={50} />
          <img src={User11} width={50} height={50} />
          <img className="  users-image-setting" src={User12} width={50} />
        </div>
    </div>
  )
}

export default Users
