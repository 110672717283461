import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BlogPage = () => {
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const postsPerPage = 5;

    // Fetch posts from an API or server
    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get(`https://api.example.com/posts?page=${currentPage}&limit=${postsPerPage}`);
                setPosts(response.data.posts);
                setTotalPages(response.data.totalPages);
            } catch (error) {
                console.error("Error fetching blog posts:", error);
            }
        };
        fetchPosts();
    }, [currentPage]);

    // Pagination handler
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    return (
        <div className="container mx-auto mt-10 px-4">
            {/* Blog Header */}
            <header className="mb-10 text-center">
                <h1 className="text-4xl font-bold">Our Blog</h1>
                <p className="text-gray-600 mt-2">Insights and stories from our team</p>
            </header>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
                {/* Main Blog Content */}
                <section className="col-span-2">
                    {/* Static Blog Post */}
                    <BlogPost post={{
                        id: "static-1",
                        title: "The Future of Work: Flexible Platforms for Subcontracting",
                        date: "November 5, 2024",
                        summary: "Explore how collaborative platforms like BtoBJob are transforming the future of work through flexible, project-based jobs and a supportive professional community.",
                        content: "As our world becomes increasingly interconnected, the landscape of work is evolving in exciting ways..."
                    }} />

                    {/* Dynamically Loaded Posts */}
                    {posts.map(post => (
                        <BlogPost key={post.id} post={post} />
                    ))}

                    {/* Pagination */}
                    <div className="flex justify-center mt-10">
                        <button
                            className="px-4 py-2 mx-1 bg-gray-200 text-gray-700 rounded"
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span className="px-4 py-2 text-gray-700">Page {currentPage} of {totalPages}</span>
                        <button
                            className="px-4 py-2 mx-1 bg-gray-200 text-gray-700 rounded"
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                </section>

                {/* Sidebar (optional) */}
                <aside className="bg-gray-100 p-6 rounded-lg shadow-md">
                    <h2 className="text-xl font-semibold mb-4">Categories</h2>
                    <ul>
                        <li><a href="#" className="text-blue-600 hover:underline">Technology</a></li>
                        <li><a href="#" className="text-blue-600 hover:underline">Business</a></li>
                        <li><a href="#" className="text-blue-600 hover:underline">Lifestyle</a></li>
                        <li><a href="#" className="text-blue-600 hover:underline">Productivity</a></li>
                    </ul>

                    <h2 className="text-xl font-semibold mt-8 mb-4">Recent Posts</h2>
                    <ul>
                        {posts.slice(0, 5).map((post) => (
                            <li key={post.id}><a href={`/post/${post.id}`} className="text-blue-600 hover:underline">{post.title}</a></li>
                        ))}
                    </ul>
                </aside>
            </div>
        </div>
    );
};

const BlogPost = ({ post }) => (
    <div className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-2xl font-semibold mb-2">{post.title}</h2>
        <p className="text-gray-600 mb-4">{post.date}</p>
        <p className="text-gray-800">{post.summary}</p>
        <a href={`/post/${post.id}`} className="text-blue-600 hover:underline mt-4 inline-block">Read more</a>
    </div>
);

export default BlogPage;
