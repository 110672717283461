import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from "../../services/api";

const ServicesAndRental = () => {
    const [rentalEquipmentsData, setRentalEquipmentsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [radius, setRadius] = useState(10);
    const [selectedType, setSelectedType] = useState('');
    const [cityQuery, setCityQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [hasMore, setHasMore] = useState(true);

    const navigate = useNavigate();

    const types = [
        { value: "", label: "All Types" },
        { value: "service", label: "Service" },
        { value: "rental_equipment", label: "Matériel de location" },
        { value: "sale_themes", label: "Thèmes (vente)" },
        { value: "rental_utility_vehicle", label: "Véhicule utilitaire (location)" },
        { value: "rental_real_estate", label: "Immobilier (location)" },
        { value: "sale_utility_vehicle", label: "Véhicule utilitaire (vente)" },
        { value: "sale_equipment", label: "Équipement (vente)" },
        { value: "sale_real_estate", label: "Immobilier (vente)" }
    ];

    const fetchRentals = async (page) => {
        setLoading(true);
        setError(null);

        try {
            const data = await api.fetchRentals(page, 10, selectedType, radius, searchQuery, cityQuery);

            setRentalEquipmentsData(prevData => page === 1 ? data.items : [...prevData, ...data.items]);
            setTotalPages(data.totalPages);
            setHasMore(page < data.totalPages);
        } catch (err) {
            console.error('Error fetching rentals:', err);
            setError('Failed to load rentals. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    // Fetch rentals when filters or search terms change
    useEffect(() => {
        setCurrentPage(1); // Reset to first page on filter change
        fetchRentals(1);
    }, [searchQuery, cityQuery, radius, selectedType]);

    const handleLoadMore = () => {
        const nextPage = currentPage + 1;
        if (nextPage <= totalPages) {
            setCurrentPage(nextPage);
            fetchRentals(nextPage);
        }
    };

    const handleCardClick = (rentalId) => {
        navigate(`/profile/rentalAds/${rentalId}`);
    };

    return (
        <div className='bg-white rounded-xl max-w-6xl md:mt-16 mt-2 mx-auto'>
            <div className='flex flex-col p-4 md:p-2'>
                {loading && currentPage === 1 ? (
                    <p>Loading rentals...</p>
                ) : error ? (
                    <p className="text-red-500">{error}</p>
                ) : (
                    <>
                        <div className="flex flex-col md:flex-row gap-4 p-4">
                            <div className="w-full">
                                <label htmlFor="searchQuery" className="block text-sm font-medium text-gray-700">
                                    Search by title or type
                                </label>
                                <input
                                    id="searchQuery"
                                    type="text"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className="mt-1 w-full text-xs px-2 py-3 rounded-md border border-gray-300"
                                    placeholder="Enter search term"
                                />
                            </div>
                            <div className="w-full">
                                <label htmlFor="cityQuery" className="block text-sm font-medium text-gray-700">
                                    Search by city
                                </label>
                                <input
                                    id="cityQuery"
                                    type="text"
                                    value={cityQuery}
                                    onChange={(e) => setCityQuery(e.target.value)}
                                    className="mt-1 w-full text-xs px-2 py-3 rounded-md border border-gray-300"
                                    placeholder="Enter city"
                                />
                            </div>
                            <div className="w-full">
                                <label htmlFor="radius" className="block text-sm font-medium text-gray-700">
                                    Radius (km)
                                </label>
                                <input
                                    id="radius"
                                    type="number"
                                    value={radius}
                                    onChange={(e) => setRadius(Number(e.target.value))}
                                    className="mt-1 w-full text-xs px-2 py-3 rounded-md border border-gray-300"
                                    placeholder="Enter radius"
                                />
                            </div>
                            <div className="w-full">
                                <label htmlFor="typeSelect" className="block text-sm font-medium text-gray-700">
                                    Select type
                                </label>
                                <select
                                    id="typeSelect"
                                    value={selectedType}
                                    onChange={(e) => setSelectedType(e.target.value)}
                                    className="mt-1 block w-full text-xs px-2 py-3 rounded-md border border-gray-300"
                                >
                                    {types.map(({ value, label }) => (
                                        <option key={value} value={value}>
                                            {label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 px-2 py-2 gap-4">
                            {rentalEquipmentsData.map((rental) => (
                                <div
                                    key={rental.id}
                                    className="mx-auto w-full shadow-md rounded-lg overflow-hidden cursor-pointer"
                                    onClick={() => handleCardClick(rental.id)}
                                >
                                    <div className="flex h-[250px]">
                                        <img className="h-full object-contain w-52" src={rental.image} alt={rental.title} />
                                        <div className="p-1 w-full relative">
                                            <div className="font-semibold text-base">{rental.title}</div>
                                            <div className="text-gray-600 text-sm font-semibold mt-2">{rental.type}</div>
                                            {rental.boosted && (
                                                <div className="absolute top-0 right-0 bg-red-600 text-white text-xs px-2 py-1 rounded-md">
                                                    Urgent
                                                </div>
                                            )}
                                            <div className="absolute bottom-0 p-1">
                                                <span className="bg-[#65A406] text-white px-2 py-1 rounded-md">€{rental.price}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {hasMore && (
                            <div className="mt-6 flex justify-center">
                                <button
                                    onClick={handleLoadMore}
                                    disabled={loading}
                                    className={`px-4 py-2 rounded-md ${
                                        loading ? 'bg-gray-300 text-gray-500' : 'bg-lime-500 text-white hover:bg-blue-600'
                                    }`}
                                >
                                    {loading ? 'Loading...' : 'Load More'}
                                </button>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ServicesAndRental;
