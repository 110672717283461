import React, { useState, useEffect } from 'react';
import './toggleswitch.css'; // Ensure your CSS file is properly configured
import { MdDomainVerification } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import api from '../services/api'; // Adjust the path as necessary

const Toggler = () => {
  const [isProfessional, setIsProfessional] = useState(false);
  const [subscriptionExists, setSubscriptionExists] = useState(false);

  const navigate = useNavigate();

  // Fetch subscription status on component mount
  useEffect(() => {
    const getSubscription = async () => {
      try {
        const data = await api.getSubscription();
        setSubscriptionExists(data.subscription_exists);
        setIsProfessional(data.subscription_exists);
      } catch (error) {
        console.error('Error fetching subscription status:', error);
      }
    };

    getSubscription();
  }, []);

  const handleToggle = () => {
    setIsProfessional(!isProfessional);
    // You might want to update the subscription status here
  };

  const NavigateToverify = () => {
    window.location.href = "https://btob.btobjob.com/verify.php";
  };

  return (
    <div className="  flex flex-col items-end mt-4">
      <div className="  flex items-center mb-4">
        <div onClick={NavigateToverify} className='border rounded-full border-[#65a406] p-1 cursor-pointer'>
         <MdDomainVerification color='#65a406'/>
        </div>
      </div>
      <div className="  flex space-x-4 border-2 p-1 ml-14 sm:ml-0">
        <button
          className={`sm:px-2 sm:py-2 rounded text-[8px] sm:text-xs p-1 ${!isProfessional ? 'bg-[#65a406] text-white' : 'bg-gray-200 text-gray-500'}`}
  
        >
           Particulier
        </button>
        <button
          className={`sm:px-4 sm:py-2 rounded text-[8px] sm:text-xs p-1 ${isProfessional ? 'bg-[#65a406] text-white' : 'bg-gray-200 text-gray-500'}`}
          
        >
        Professionnel
        </button>
      </div>
    </div>
  );
};

export default Toggler;
