import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";

function Choice() {
    const [close, setClose] = useState(true);
    const [isVisible, setIsVisible] = useState(true);
    const cardRef = useRef(null);
    const navigate = useNavigate();

    const handleClickOutside = (event) => {
        if (cardRef.current && !cardRef.current.contains(event.target)) {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (!isVisible) return null;

    const NavigateToProfessional = () => {
        navigate("/professionaluser");
    };

    const NavigateToIndividual = () => {
        navigate("/individualsignup");
    };

    return (
        <div className={`${close ? "flex" : "hidden"} items-center justify-center fixed inset-0 z-100 bg-black/50`}>
            <div className="  fixed inset-0 flex items-center justify-center">
                <div className='bg-[#E7F8CD] p-10 rounded-xl'>
                    <div ref={cardRef} className="  max-w-sm mx-auto bg-white p-6 rounded-xl shadow-lg relative">
                        <div className="  text-center flex flex-col gap-5">
                            <p className='text-2xl font-bold text-[#65A406]'>Créer un compte</p>
                            <p>Vous êtes professionnels ou particuliers</p>
                            <button
                                onClick={NavigateToProfessional}
                                type="button"
                                className="translatable-text  w-full py-2 px-2 bg-gradient-to-r from-lime-300 to-[#65A406] text-white font-semibold rounded-2xl hover:bg-green-700"
                            >
                                Professionals
                            </button>

                            <button
                                onClick={NavigateToIndividual}
                                type="button"
                                className=" translatable-text w-full py-2 px-2 bg-gradient-to-r from-lime-300 to-[#65A406] text-white font-semibold rounded-2xl hover:bg-green-700"
                            >
                             Particuliers
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Choice;
