import React,{useState} from 'react'
import { FaArrowLeft, FaHeart,FaRegHeart } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import category from "../../assests/photos3.png"

const Allthemes1 = () => {
    const Data = [
        {
            id: 1,
            imgSrc: category,
            label: "Popular",
            location: "Asheville, North Carolina",
            liked: false
        },
        {
            id: 2,
            imgSrc: category,
            label: "Popular",
            location: "Asheville, North Carolina",
            liked: false
        },
        {
            id: 3,
            imgSrc: category,
            label: "Popular",
            location: "Asheville, North Carolina",
            liked: false
        },
        {
            id: 4,
            imgSrc: category,
            label: "Popular",
            location: "Asheville, North Carolina",
            liked: false
        },
        {
            id: 5,
            imgSrc: category,
            label: "Popular",
            location: "Asheville, North Carolina",
            liked: false
        },
        {
            id: 6,
            imgSrc: category,
            label: "Popular",
            location: "Asheville, North Carolina",
            liked: false
        }
    ];

    const [data, setData] = useState(Data);

    const toggleLike = (id) => {
        setData(data.map(website =>
            website.id === id ? { ...website, liked: !website.liked } : website
        ));
    };
    const navigate = useNavigate();
    const NavigateToservices_rentals = () => {
        navigate("/profile/services_rentals");
    };
    const NavigateToBack = () => {
        navigate(-1); // Navigates back one step in the history stack
    };
    return (
        <div className="  md:mx-auto md:max-w-3xl lg:max-w-4xl bg-white rounded-lg md:mt-12 m-2">
            <div className='flex flex-col'>
                <div className='p-6 border-b-2 flex flex-col'>
                    <div className='flex items-center'>
                        <FaArrowLeft onClick={NavigateToBack} className='h-6 w-6 pr-2' />
                         <p  ClassName="    text-xl font-semibold">Tous les themes</p>
                    </div>
                    <p className='text-xs'>Gagnez du temps et postez vos demandes en un clic</p>
                </div>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3'>
                    {data.map(website => (
                        <div key={website.id} className="  p-4 relative">
                            <div className="  bg-white border rounded-lg w-full">
                                <div onClick={NavigateToservices_rentals} className=''> <img src={website.imgSrc} className='w-full rounded-tl-lg rounded-tr-lg object-contain' /></div>

                                <div className="  flex items-center justify-between mx-4 mt-3 mb-2">
                                    <div className="  flex gap-5">
                                        {website.liked ? (
                                            <FaHeart color='red' size={24} onClick={() => toggleLike(website.id)} />
                                        ) : (
                                            <FaRegHeart color='black' size={24} onClick={() => toggleLike(website.id)} />
                                        )}
                                        {/* <svg fill="#262626" height="24" viewBox="0 0 48 48" width="24">
                                            <path clipRule="evenodd" d="M47.5 46.1l-2.8-11c1.8-3.3 2.8-7.1 2.8-11.1C47.5 11 37 .5 24 .5S.5 11 .5 24 11 47.5 24 47.5c4 0 7.8-1 11.1-2.8l11 2.8c.8.2 1.6-.6 1.4-1.4zm-3-22.1c0 4-1 7-2.6 10-.2.4-.3.9-.2 1.4l2.1 8.4-8.3-2.1c-.5-.1-1-.1-1.4.2-1.8 1-5.2 2.6-10 2.6-11.4 0-20.6-9.2-20.6-20.5S12.7 3.5 24 3.5 44.5 12.7 44.5 24z" fillRule="evenodd"></path>
                                        </svg> */}
                                    </div>
                                    {/* <div className="  flex">
                                        <svg fill="#262626" height="24" viewBox="0 0 48 48" width="24">
                                            <path d="M43.5 48c-.4 0-.8-.2-1.1-.4L24 29 5.6 47.6c-.4.4-1.1.6-1.6.3-.6-.2-1-.8-1-1.4v-45C3 .7 3.7 0 4.5 0h39c.8 0 1.5.7 1.5 1.5v45c0 .6-.4 1.2-.9 1.4-.2.1-.4.1-.6.1zM24 26c.8 0 1.6.3 2.2.9l15.8 16V3H6v39.9l15.8-16c.6-.6 1.4-.9 2.2-.9z"></path>
                                        </svg>
                                    </div> */}
                                </div>
                            </div>
                            {/* {website.label && (
                                <div className='bg-[#65A406] w-28 rounded-lg flex justify-center absolute top-7 left-3'>
                                    <p className='text-white font-bold'>{website.label}</p>
                                </div>
                            )} */}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Allthemes1;
