import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const API_URL = 'https://btob.btobjob.com';

const AdsAndRequests = () => {
  const [ads, setAds] = useState([]);
  const [requests, setRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [radius, setRadius] = useState('');
  const [searchTheme, setSearchTheme] = useState('');
  const [searchLocation, setSearchLocation] = useState('');
  const [urgencyFilter, setUrgencyFilter] = useState('all');
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const itemsPerPage = 10;
  const navigate = useNavigate();

  // Function to fetch ads and requests
  const fetchAdsAndRequests = async () => {
    try {
      setLoading(true);

      const params = {
        page: currentPage,
        itemsPerPage,
        search: searchTheme || undefined,
        location: searchLocation || undefined,
        urgency: urgencyFilter !== 'all' ? urgencyFilter : undefined,
        radius: radius || undefined,
      };

      const response = await axios.get(`${API_URL}/fetch-all-ads.php`, {
        params,
        withCredentials: true,
      });

      if (response.data && response.data.results) {
        const newAds = response.data.results.ads || [];
        const newRequests = response.data.results.requests || [];

        setAds((prev) => (currentPage === 1 ? newAds : [...prev, ...newAds]));
        setRequests((prev) => (currentPage === 1 ? newRequests : [...prev, ...newRequests]));

        setHasMore(newAds.length > 0 || newRequests.length > 0);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching ads and requests:', error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  // Debounced effect for filters
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCurrentPage(1); // Reset to the first page on filter change
      fetchAdsAndRequests();
    }, 500); // Debounce delay

    return () => clearTimeout(timeoutId); // Cleanup timeout
  }, [radius, searchTheme, searchLocation, urgencyFilter]);

  // Effect to handle pagination
  useEffect(() => {
    if (currentPage > 1) {
      fetchAdsAndRequests();
    }
  }, [currentPage]);

  const handleItemClick = (id, type) => {
    if (type === 'ad') {
      navigate(`/profile/AdsDetails?ad_id=${id}`);
    } else {
      navigate(`/profile/RequestDetails?request_id=${id}`);
    }
  };

  const loadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  return (
    <div className="max-w-6xl mx-auto p-4 mt-10">
      {/* Filters Section */}
      <div className="mb-6 grid grid-cols-1 md:grid-cols-4 gap-4">
        <div>
          <label htmlFor="radius" className="block text-lg font-semibold mb-2">
            Radius (km): {radius || 'All'}
          </label>
          <input
            type="number"
            id="radius"
            min="0"
            max="100"
            value={radius}
            onChange={(e) => setRadius(e.target.value || '')}
            placeholder="Enter radius"
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label htmlFor="theme-search" className="block text-lg font-semibold mb-2">
            Search by Theme
          </label>
          <input
            type="text"
            id="theme-search"
            value={searchTheme}
            onChange={(e) => setSearchTheme(e.target.value)}
            placeholder="Enter theme..."
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label htmlFor="location-search" className="block text-lg font-semibold mb-2">
            Search by Location
          </label>
          <input
            type="text"
            id="location-search"
            value={searchLocation}
            onChange={(e) => setSearchLocation(e.target.value)}
            placeholder="Enter location..."
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label htmlFor="urgency" className="block text-lg font-semibold mb-2">
            Urgency Filter
          </label>
          <select
            id="urgency"
            value={urgencyFilter}
            onChange={(e) => setUrgencyFilter(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
          >
            <option value="all">All</option>
            <option value="urgent">Urgent</option>
            <option value="non-urgent">Non-Urgent</option>
          </select>
        </div>
      </div>

      {/* Ads and Requests List */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
        {ads.map((ad) => (
          <div
            key={ad.id}
            className="bg-white shadow-md rounded-lg p-4 relative hover:shadow-lg transition-shadow duration-300 cursor-pointer"
            onClick={() => handleItemClick(ad.id, 'ad')}
          >
            {ad.image && (
              <img
                src={ad.image}
                alt={ad.title}
                className="w-full h-52 object-cover rounded-md mb-4"
              />
            )}
            <h2 className="text-lg font-bold mb-2">{ad.title}</h2>
            <p className="text-sm text-gray-700">{ad.location}</p>
            <p className="text-sm text-gray-700">{ad.profession}</p>
            <p className="text-sm text-gray-700">{ad.created_at}</p>
            {ad.boosted === 1 && (
              <span className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded-md text-sm">
                Urgent
              </span>
            )}
          </div>
        ))}

        {requests.map((request) => (
          <div
            key={request.id}
            className="bg-white shadow-md rounded-lg p-4 relative hover:shadow-lg transition-shadow duration-300 cursor-pointer"
            onClick={() => handleItemClick(request.id, 'request')}
          >
            <h2 className="text-lg font-bold mb-2">{request.request}</h2>
            <p className="text-sm text-gray-700">Category: {request.category}</p>
            <p className="text-sm text-gray-700">Price: {request.price} €</p>
            <p className="text-sm text-gray-700">Address: {request.address}</p>
            <p className="text-sm text-gray-700">Time: {request.time}</p>
          </div>
        ))}
      </div>

      {/* Load More Button */}
      {hasMore && (
        <div className="mt-6 flex justify-center">
          <button
            onClick={loadMore}
            disabled={loading}
            className={`px-4 py-2 rounded-md ${
              loading ? 'bg-gray-300 text-gray-500' : 'bg-lime-500 text-white hover:bg-blue-600'
            }`}
          >
            {loading ? 'Loading...' : 'Load More'}
          </button>
        </div>
      )}
    </div>
  );
};

export default AdsAndRequests;
