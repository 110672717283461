import React, { useState } from 'react';
import './user.css';
import LOGO from '../../assests/websitelogo.png';
import { Link } from 'react-router-dom';
import ProfessionalUpload from '../../assests/professionalupload.png';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { FiEye, FiEyeOff } from 'react-icons/fi'; // Import eye icons

import Terms from './Terms'; // Import Terms component

function ProfessionalSignup() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        siret: '', // Siret Number (Kbis)
        insurance: null,
        civilLiability: null
    });
    const [isVerifying, setIsVerifying] = useState(false);
    const [noInsurance, setNoInsurance] = useState(false);
    const [noCivilLiability, setNoCivilLiability] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false);
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value
        });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        if (name === 'noInsurance') {
            setNoInsurance(checked);
            if (checked) {
                setFormData({ ...formData, insurance: null });
            }
        } else if (name === 'noCivilLiability') {
            setNoCivilLiability(checked);
            if (checked) {
                setFormData({ ...formData, civilLiability: null });
            }
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.password !== formData.confirmPassword) {
            alert('Passwords do not match!');
            return;
        }

        setIsVerifying(true);

        const form = new FormData();
        form.append('name', formData.name);
        form.append('email', formData.email);
        form.append('phone', formData.phone);
        form.append('password', formData.password);
        form.append('siret', formData.siret);
        if (!noInsurance && formData.insurance) form.append('insurance', formData.insurance);
        if (!noCivilLiability && formData.civilLiability) form.append('civil_liability', formData.civilLiability);

        try {
            const response = await axios.post('https://btob.btobjob.com/professional-signup.php', form, {
                withCredentials: true
            });

            if (response.data.status === "success") {
                alert('Signup successful!');
                localStorage.setItem('email', formData.email);
                window.location.href = 'https://btob.btobjob.com/verify_email.php';
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            alert('There was an error! ' + (error.response ? error.response.data.message : error.message));
        } finally {
            setIsVerifying(false);
        }
    };

    return (
        <div className="  flex justify-center items-center min-h-screen shadow-2xl">
            <div className='w-96 p-6 rounded-3xl shadow-lg bg-[#E1F5C4]'>
                <h4 className='text-2xl font-bold text-[#495057] text-center mb-4'>Créer un compte Professionnel</h4>
                <p className='text-center text-[#495057] mb-6'>Nous sommes heureux de vous voir</p>
                <form onSubmit={handleSubmit} className="  space-y-4">
                    <div>
                        <p className='text-sm font-medium text-[#495057]'>Nom et prénom</p>
                        <div className='mt-1'>
                            <input className='w-full rounded-full p-3 border border-gray-300 text-[#495057] placeholder-[#495057] focus:ring-2 focus:ring-[#75C00A] focus:outline-none' 
                                type='text' 
                                name='name' 
                                placeholder='Entrez votre nom complet' 
                                onChange={handleChange} 
                                required 
                            />
                        </div>
                    </div>
                    <div>
                        <p className='text-sm font-medium text-[#495057]'>E-mail</p>
                        <div className='mt-1'>
                            <input className='w-full rounded-full p-3 border border-gray-300 text-[#495057] placeholder-[#495057] focus:ring-2 focus:ring-[#75C00A] focus:outline-none' 
                                type='email' 
                                name='email' 
                                placeholder='E-mail' 
                                onChange={handleChange} 
                                required 
                            />
                        </div>
                    </div>
                    <div>
                        <p className='text-sm font-medium text-[#495057]'>Numéro de téléphone</p>
                        <div className='mt-1'>
                            <input className='w-full rounded-full p-3 border border-gray-300 text-[#495057] placeholder-[#495057] focus:ring-2 focus:ring-[#75C00A] focus:outline-none' 
                                type='text' 
                                name='phone' 
                                placeholder='Entrez votre numéro' 
                                onChange={handleChange} 
                                required 
                            />
                        </div>
                    </div>
                    <div>
                        <p className='text-sm font-medium text-[#495057]'>Siret Number (Kbis)</p>
                        <div className='mt-1'>
                            <input className='w-full rounded-full p-3 border border-gray-300 text-[#495057] placeholder-[#495057] focus:ring-2 focus:ring-[#75C00A] focus:outline-none' 
                                type='text' 
                                name='siret' 
                                placeholder='Entrez votre numéro de Siret' 
                                onChange={handleChange} 
                                required 
                            />
                        </div>
                    </div>
                    <div>
                        <p className='text-sm font-medium text-[#495057]'>Téléchargez votre Assurance Decennal</p>
                        <div className='mt-1'>
                            <div className="  flex items-center space-x-3">
                                <img src={ProfessionalUpload} width={20} className="  professional-upload-icon" alt="Upload Icon" />
                                <input className='block w-full text-sm text-[#495057] border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:ring-2 focus:ring-[#75C00A]' 
                                    type='file' 
                                    name='insurance' 
                                    onChange={handleChange} 
                                    required={!noInsurance} 
                                    disabled={noInsurance} 
                                />
                            </div>
                            <label className="  ml-2 text-xs text-[#495057] font-semibold mt-2">
                                <input type="checkbox" name="noInsurance" onChange={handleCheckboxChange} /> Je n'ai pas d'assurance
                            </label>
                        </div>
                    </div>
                    <div>
                        <p className='text-sm font-medium text-[#495057]'>Téléchargez votre Responsabilité Civile</p>
                        <div className='mt-1'>
                            <div className="  flex items-center space-x-3">
                                <img src={ProfessionalUpload} width={20} className="  professional-upload-icon" alt="Upload Icon" />
                                <input className='block w-full text-sm text-[#495057] border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:ring-2 focus:ring-[#75C00A]' 
                                    type='file' 
                                    name='civilLiability' 
                                    onChange={handleChange} 
                                    required={!noCivilLiability} 
                                    disabled={noCivilLiability} 
                                />
                            </div>
                            <label className="  ml-2 text-xs text-[#495057] font-semibold mt-2">
                                <input type="checkbox" name="noCivilLiability" onChange={handleCheckboxChange} /> Je n'ai pas de responsabilité civile
                            </label>
                        </div>
                    </div>
                    <div>
                        <p className='text-sm font-medium text-[#495057]'>Mot de passe</p>
                        <div className='mt-1 relative'>
                            <input 
                                className='w-full rounded-full p-3 border border-gray-300 text-[#495057] placeholder-[#495057] focus:ring-2 focus:ring-[#75C00A] focus:outline-none'
                                type={showPassword ? 'text' : 'password'} 
                                name='password' 
                                placeholder='Entrez votre mot de passe' 
                                onChange={handleChange} 
                                required 
                            />
                            <button type="button" className="  absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5" onClick={togglePasswordVisibility}>
                            {showConfirmPassword ? <FiEyeOff /> : <FiEye />} {/* Toggle eye icon */}
                            </button>
                        </div>
                    </div>

                    {/* Confirm password field */}
                    <div>
                        <p className='text-sm font-medium text-[#495057]'>Confirmer le mot de passe</p>
                        <div className='mt-1 relative'>
                            <input 
                                className='w-full rounded-full p-3 border border-gray-300 text-[#495057] placeholder-[#495057] focus:ring-2 focus:ring-[#75C00A] focus:outline-none' 
                                type={showConfirmPassword ? 'text' : 'password'} 
                                name='confirmPassword' 
                                placeholder='Confirmez votre mot de passe' 
                                onChange={handleChange} 
                                required 
                            />
                            <button type="button" className="  absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5" onClick={toggleConfirmPasswordVisibility}>
                                {showConfirmPassword ? <FiEyeOff /> : <FiEye />} 
                            </button>
                        </div>
                    </div>
                         
                    <div className='flex items-center space-x-2 my-2'>
                        <input type="checkbox" required onClick={() => setShowTermsModal(true)}/>
                        <p className='text-xs text-[#495057] font-semibold'>J'accepte toutes les déclarations incluses dans les conditions d'utilisation<button type="button" className="underline text-red-500" onClick={() => setShowTermsModal(true)}>conditions d'utilisation</button></p>
                    </div>
                    <div className='text-center'>
                        <button className='px-6 py-2 rounded-full bg-[#75C00A] text-white text-lg font-semibold hover:bg-[#66A309] transition duration-300' type='submit'>
                            {isVerifying ? 'Verifying...' : "S'inscrire"}
                        </button>
                    </div>
                </form>
                <p className='text-center text-[#495057] mt-4'>or</p>
                <p className='text-center text-[#495057]'>Vous avez déjà un compte</p>
                <div className='text-center'>
                    <button className='px-6 py-2 mt-4 rounded-full border-2 border-[#75C00A] text-[#75C00A] text-lg font-semibold hover:bg-[#75C00A] hover:text-white transition duration-300'>
                        <Link className='login-link' to="/login-pro">Se connecter</Link>
                    </button>
                </div>

                {showTermsModal && <Terms onClose={() => setShowTermsModal(false)} />} {/* Render Terms modal */}
            </div>
        </div>
    );
}

export default ProfessionalSignup;
