import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const subscriptionKey = '39479f4cde37449dad3ac26ca017be9f'; // Replace with your Azure subscription key
const region = 'southeastasia'; // Replace with your Azure region

const LanguageDropdown = ({ selectedLanguage, onLanguageChange, targetSelector }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    // Function to translate text
    const translateText = async (text, targetLang) => {
        try {
            const response = await axios({
                baseURL: 'https://api.cognitive.microsofttranslator.com/',
                url: '/translate',
                method: 'POST',
                headers: {
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                    'Ocp-Apim-Subscription-Region': region,
                    'Content-Type': 'application/json',
                },
                params: {
                    'api-version': '3.0',
                    'to': targetLang,
                },
                data: [{
                    Text: text,
                }],
            });
            return response.data[0].translations[0].text;
        } catch (error) {
            console.error('Translation error:', error);
            return null;
        }
    };

    // Capture text from all elements with the specified class
    const captureAndTranslateText = async (languageCode) => {
        if (!targetSelector) {
            console.error('No target selector provided');
            return;
        }

        try {
            const targetElements = document.querySelectorAll(targetSelector); // Select all matching elements
            if (targetElements.length === 0) {
                console.error(`No elements found for selector: ${targetSelector}`);
                return;
            }

            // Loop through each element and translate its content
            targetElements.forEach(async (element) => {
                const textToTranslate = element.innerText;
                const translatedText = await translateText(textToTranslate, languageCode);

                if (translatedText) {
                    element.innerText = translatedText; // Update each element with translated text
                }
            });
        } catch (error) {
            console.error(`Error handling translation for selector: ${targetSelector}`, error);
        }
    };

    const handleLanguageChange = async (languageCode) => {
        onLanguageChange(languageCode);
        setIsDropdownOpen(false);

        // Capture and translate text in all elements with the target selector
        captureAndTranslateText(languageCode);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div ref={dropdownRef} className="relative mt-1 me-2 inline-block">
            <button
                className="navbar-link flex items-center"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
                {/* World icon */}
                <svg
                    className="w-4 h-4 mr-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 2a10 10 0 100 20 10 10 0 000-20zm0 0a10 10 0 000 20m-5-10a5 5 0 0110 0m-10 0a5 5 0 0010 0m-5-5a5 5 0 00-5 5m10-5a5 5 0 00-5 5m0-5a5 5 0 015 5" />
                </svg>

                {/* Current language */}
                {selectedLanguage === 'fr' && 'Français'}
                {selectedLanguage === 'en' && 'English'}
                {selectedLanguage === 'es' && 'Español'}
                {selectedLanguage === 'de' && 'Deutsch'}
                {selectedLanguage === 'it' && 'Italiano'}
                {selectedLanguage === 'be' && 'Belge'}
                {selectedLanguage === 'ch' && 'Suisse'}

                <svg
                    className="w-4 h-4 ml-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                </svg>
            </button>

            {isDropdownOpen && (
                <ul className="absolute right-0 mt-2 w-40 bg-white shadow-lg rounded-md z-50">
                    <li>
                        <button onClick={() => handleLanguageChange('fr')} className="block px-4 py-2 text-sm hover:bg-gray-100">
                            Français
                        </button>
                    </li>
                    <li>
                        <button onClick={() => handleLanguageChange('en')} className="block px-4 py-2 text-sm hover:bg-gray-100">
                            English
                        </button>
                    </li>
                    <li>
                        <button onClick={() => handleLanguageChange('es')} className="block px-4 py-2 text-sm hover:bg-gray-100">
                            Español
                        </button>
                    </li>
                    <li>
                        <button onClick={() => handleLanguageChange('de')} className="block px-4 py-2 text-sm hover:bg-gray-100">
                            Deutsch
                        </button>
                    </li>
                    <li>
                        <button onClick={() => handleLanguageChange('it')} className="block px-4 py-2 text-sm hover:bg-gray-100">
                            Italiano
                        </button>
                    </li>
                    <li>
                        <button onClick={() => handleLanguageChange('be')} className="block px-4 py-2 text-sm hover:bg-gray-100">
                            Belge
                        </button>
                    </li>
                    <li>
                        <button onClick={() => handleLanguageChange('ch')} className="block px-4 py-2 text-sm hover:bg-gray-100">
                            Suisse
                        </button>
                    </li>
                </ul>
            )}
        </div>
    );
};

export default LanguageDropdown;
