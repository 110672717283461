import React, { useState } from "react";

const MessageModal = ({ isOpen, onClose, onSend }) => {
  const [message, setMessage] = useState("");

  if (!isOpen) return null;

  const handleSend = () => {
    if (message.trim() === "") {
      alert("Message cannot be empty");
      return;
    }
    onSend(message);
    setMessage("");
    onClose();
  };

  return (
    <div className="  fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[999]">
      <div className="  bg-white p-4 rounded-lg shadow-lg w-80">
         <h2  ClassName="    text-lg font-semibold mb-2">Send a Message</h2>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="  w-full p-2 border rounded-md mb-2"
          rows="5"
          placeholder="Type your message here..."
        />
        <div className="  flex justify-end">
          <button
            onClick={onClose}
            className="  bg-red-500 text-white px-4 py-2 rounded-md mr-2"
          >
            Cancel
          </button>
          <button
            onClick={handleSend}
            className="  bg-green-500 text-white px-4 py-2 rounded-md"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessageModal;