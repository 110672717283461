import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios'; // Import axios

const ViewRequestsAndAds = () => {
  const [requests, setRequests] = useState([]); // Default to empty array
  const [ads, setAds] = useState([]); // Default to empty array
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();
  const limit = 10; // Define a consistent number of items per page

  useEffect(() => {
    const fetchRequestsAndAds = async () => {
      try {
        const response = await axios.get(`https://btob.btobjob.com/get-requests-by-id.php?page=${page}&limit=${limit}`, { withCredentials: true });
        console.log('API response:', response.data); // Debugging log
  
        // Destructure privateRequests and ads to match PHP response
        const { privateRequests = [], ads = [], total = 0 } = response.data; 
        setRequests(privateRequests); // Set privateRequests to requests state
        setAds(ads); 
        setTotal(total);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
  
    fetchRequestsAndAds();
  }, [page]);
  
  // Handle profile redirection based on user_id from requests and ads
  const handleRequestClick = (userId) => {
    navigate(`/profile/specificUser?professional_id=${userId}`);
  };

  const handleNextPage = () => {
    if (page * limit < total) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <div className="  max-w-2xl mx-auto p-8 bg-white shadow-lg rounded-lg mt-10">
       <h2  ClassName="    text-3xl font-bold mb-6 text-center text-gray-700">demandes privées reçues</h2>

      {loading ? (
         <p  ClassName="    text-center">Loading...</p>
      ) : (
        <>
          {/* Display Requests */}
          <div className="  space-y-6">
            {requests && requests.length === 0 ? (
               <p  ClassName="    text-center">No requests found.</p>
            ) : (
              requests.map((request) => (
                <div key={request.id} className="  p-4 border border-gray-300 rounded-lg">
                   <h3  ClassName="    text-lg  mb-2"><b>Description: </b>{request.description}</h3>
                  <div className="  flex justify-between mb-4">
                    <div className="  flex flex-col">
                      <span className="  font-semibold">Category:</span>
                      <span>{request.category}</span>
                    </div>
                    <div className="  flex flex-col">
                      <span className="  font-semibold">Price:</span>
                      <span>{request.price} €</span>
                    </div>
                    <div className="  flex flex-col">
                      <span className="  font-semibold">Address:</span>
                      <span>{request.address}</span>
                    </div>
                    <div className="  flex flex-col">
                      <span className="  font-semibold">Time:</span>
                      <span>{request.created_at}</span>
                    </div>
                  </div>
                  <div className="  flex gap-4">
                    {request.file1 && <img src={request.file1} alt="File 1" className="  w-20 h-20 object-cover rounded-lg" />}
                    {request.file2 && <img src={request.file2} alt="File 2" className="  w-20 h-20 object-cover rounded-lg" />}
                    {request.file3 && <img src={request.file3} alt="File 3" className="  w-20 h-20 object-cover rounded-lg" />}
                  </div>
                  <button
      onClick={() => handleRequestClick(request.user_id)}  // Ensure user_id is passed correctly
      className="  bg-lime-500 text-white px-4 py-2 mt-4 rounded hover:bg-blue-600 transition duration-300"
    >
      View Profile
    </button>
                </div>
              ))
            )}
          </div>

          {/* Display Ads */}
          <div className="  space-y-6 mt-8">
             <h3  ClassName="    text-2xl font-bold text-center text-gray-700">annonces reçues</h3>
            {ads && ads.length === 0 ? (
               <p  ClassName="    text-center">No ads found.</p>
            ) : (
              ads.map((ad) => (
                <div key={ad.id} className="  p-4 border border-gray-300 rounded-lg">
                   <h3  ClassName="    text-lg font-bold mb-2">{ad.title}</h3>
                  <p>{ad.description}</p>
                  {ad.images && (
                    <div className="  flex gap-4 mt-2">
                      {ad.images.map((image, index) => (
                        <img key={index} src={image} alt={`Ad Image ${index + 1}`} className="  w-20 h-20 object-cover rounded-lg" />
                      ))}
                    </div>
                  )}
                   <p  ClassName="    text-muted mt-2">Soumis le: {ad.created_at}</p>
                  <button
                    onClick={() => handleRequestClick(ad.user_id)} // Use user_id from ads
                    className="  bg-lime-500 text-white px-4 py-2 mt-4 rounded hover:bg-blue-600 transition duration-300"
                  >
                    View Profile
                  </button>
                </div>
              ))
            )}
          </div>
        </>
      )}

      <div className="  flex justify-between mt-6">
        <button 
          onClick={handlePrevPage} 
          className="  bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition duration-300"
          disabled={page === 1}
        >
          Previous
        </button>
        <button 
          onClick={handleNextPage} 
          className="  bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition duration-300"
          disabled={page * limit >= total}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ViewRequestsAndAds;
