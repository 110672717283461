import './login.css'
import { useState, useEffect, useRef } from 'react';
import { MdOutlineNotifications } from "react-icons/md";
import { RiContactsLine } from "react-icons/ri";
import LOGO2 from '../assests/b2bjob logo 1.png'
import { FaHome } from "react-icons/fa";
import { RiDiscountPercentFill } from "react-icons/ri";
import { MdAddComment } from "react-icons/md";
import { IoPersonAdd } from "react-icons/io5";
import { MdForum } from "react-icons/md";
import UserProfile from '../assests/profileuser.png'
import { BiSolidMessageRoundedError } from "react-icons/bi";
import { HiSearchCircle } from "react-icons/hi";
import { CgWebsite } from "react-icons/cg";
import { FaInfoCircle } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { IoMdShare } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { MdCancel } from "react-icons/md";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { BiSolidCategoryAlt } from "react-icons/bi";
import PublicOrPrivate from './PublicOrPrivate';
import ProfileShare from './ProfileView/ProfileShare';
import { BiSolidMessageSquare } from "react-icons/bi";
import Notifications from './Notifications';
import requests from './RequestPage/Request'
import api from '../services/api'
import { FaFlag } from 'react-icons/fa';
import { FaSignOutAlt } from 'react-icons/fa';
import { FaArrowAltCircleUp } from "react-icons/fa";
import { FaCartPlus } from "react-icons/fa";
import { FaPoll } from "react-icons/fa";
import { FaRegIdCard } from "react-icons/fa";
import { FaRegImage } from "react-icons/fa";
import { FaTrello } from "react-icons/fa";
import ChatBot from './chatbot'; // Import your ChatBot component
import ChatBotIcon from './ChatBotIcon';
import axios from 'axios';
import LanguageDropdown from '../language/LanguageDropdown';
import { FaExclamationTriangle  } from 'react-icons/fa'; 
function NavbarProfile() {
    const [isOpen, setIsOpen] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [nav, setNav] = useState(false);
    const [openDiv, setOpenDiv] = useState(false);
    const [profileImagePreview, setProfileImagePreview] = useState(null);
    const [openShare, setOpenShare] = useState(false);
    const [openNotif, setopenNotif] = useState(false);
    const [isChatOpen, setIsChatOpen] = useState(false); // State to toggle ChatBot visibility
    const [isChatVisible, setIsChatVisible] = useState(true); // State to toggle ChatBot visibility
    const chatContainerRef = useRef(null); // Reference for ChatBot container
    const dropdownMenuRef = useRef(null); // Reference for dropdown
    const [open, setOpen] = useState(false);
    const [isDropOpen, setIsDropOpen] = useState(false); 
    const [unseenNotifications, setUnseenNotifications] = useState(false); 
    const [isProfessional, setIsProfessional] = useState(false);
    const [newNotifications, setNewNotifications] = useState([]); 
    const [selectedLanguage, setSelectedLanguage] = useState('fr');  // French as the default language
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to toggle the dropdown
    const toggleDropdown = (e) => {
        const dropdownMenu = e.currentTarget.nextElementSibling; // Get the ul element
        dropdownMenu.classList.toggle('hidden'); // Toggle hidden class to show/hide the dropdown
      };
    const handleIconClick = () => {
      setIsChatOpen(true); // Open the ChatBot when the icon is clicked
    };
  
    const handleCloseChat = () => {
      setIsChatOpen(false); // Close the ChatBot when 'X' is clicked
    };
  
    const dropdownRef = useRef(null); // Reference for dropdown

    const getDivState = (state) => {
        setOpenDiv(state);
    };

    const getNotifState = (state) => {
        setopenNotif(state);
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

   // Function to check if a notification is within 10 seconds from now
   const isNotificationRecent = (timestamp) => {
    const notificationTime = new Date(timestamp); // Convert timestamp to Date object
    const currentTime = new Date(); // Get the current time
    const timeDifference = (currentTime - notificationTime) / 1000; // Difference in seconds

    return timeDifference <= 10; // Return true if it's within 10 seconds
};

// Function to trigger desktop notifications
const showDesktopNotification = (notification) => {
    if (Notification.permission === 'granted') {
        const options = {
            body: notification.message,
            icon: '/path-to-your-icon.png', // Optional: add an icon for the notification
            timestamp: notification.timestamp, // Include timestamp if needed
        };
        new Notification('New Notification', options); // Create the notification
    }
};

// Request permission for desktop notifications
const requestNotificationPermission = () => {
    if ('Notification' in window && Notification.permission !== 'granted') {
        Notification.requestPermission().then(permission => {
            if (permission !== 'granted') {
                console.error('Permission for desktop notifications denied');
            }
        });
    }
};

const fetchNotifications = async () => {
    try {
        const response = await axios.post('https://btob.btobjob.com/push-notifications.php', {}, { withCredentials: true });
        if (response.data && Array.isArray(response.data.notifications)) {
            const recentNotifications = response.data.notifications
                .filter(notification => notification.is_seen === 0 && isNotificationRecent(notification.timestamp)); // Filter by recency
            setUnseenNotifications(recentNotifications.length > 0);
            setNewNotifications(recentNotifications); // Update only recent notifications

            // Trigger desktop notification for each new notification
            recentNotifications.forEach(notification => {
                showDesktopNotification(notification);
            });
        } else {
            console.error('No notifications array found');
        }
    } catch (error) {
        console.error('Error fetching notifications:', error);
    }
};

// Polling for notifications every 10 seconds
useEffect(() => {
    requestNotificationPermission(); // Request permission on component mount
    const notificationInterval = setInterval(fetchNotifications, 1000);
    return () => clearInterval(notificationInterval); // Clean up on component unmount
}, []);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const profileData = await api.fetchProfileImage();
                setProfileImagePreview(profileData.imageUrl);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };
        fetchImages();
    }, []);
    useEffect(() => {
        const fetchNotifications = async () => {
          try {
            // Make sure axios includes cookies (for sessions) in the request
            axios.defaults.withCredentials = true;
    
            const response = await axios.post('https://btob.btobjob.com/check-notifications.php');
            
            // Log response data structure to verify it
            console.log('Response data:', response.data);
    
            // Ensure 'notifications' exists and is an array
            if (response.data && Array.isArray(response.data.notifications)) {
              const hasUnseen = response.data.notifications.some(
                (notification) => notification.is_seen === 0
              );
              setUnseenNotifications(hasUnseen);
            } else {
              console.error('No notifications array found or wrong format');
              setUnseenNotifications(false);
            }
          } catch (error) {
            console.error('Error fetching notifications:', error);
          }
        };
    
        fetchNotifications();
      }, []);
    
    const handleLogout = async () => {
        try {
            await api.logout();
            setIsLoggedIn(false);
            setNav(false);
            window.location.reload();
        } catch (error) {
            console.error('Logout error', error);
        }
    };

    const navigate = useNavigate();

    const NavigateToOffers = () => navigate("/profile/offers");
    const NavigateToHome = () => navigate("/profile/request");
    const NavigateTosubscription = () => navigate("/profile/subscription");
    const NavigateTochat = () => navigate("/profile/chat");
    const NavigateToViewRequests = () => navigate("/profile/viewrequests");
    const NavigateToViewAllRequests = () => navigate("/profile/ViewAllRequests");
    const NavigateToforum = () => navigate("/profile/forum");
    const NavigateTowebsite = () => navigate("/profile/website");
    const NavigateToviewprofile = () => navigate("/profile/viewprofile");
    const NavigateToAllservices = () => navigate("/profile/services");
    const NavigateToAllacategories = () => navigate("/profile/services_rentals");
    const NavigateToAllads = () => navigate("/profile/ads");
    const NavigateToeditprofile = () => navigate("/profile/editprofile");
    const NavigateToChangePicture = () => navigate("/profile/updatepicturepage");
    const NavigateTochangepassword = () => navigate("/profile/changepassword");
    const NavigateToAddOffer = () => navigate("/profile/addoffer");
    const NavigateToCard = () => navigate("/profile/businesscard");
    const NavigateToBTBHome = () => navigate("/");
    const Navigatetoprivate = () => navigate("/profile/PrivateRequest")
    const NavigatetoOfferHistory = () => navigate("/profile/OfferHistory")
    const NavigatetoContact = () => navigate("/contact")
    useEffect(() => {
      const fetchSession = async () => {
        try {
          const sessionData = await api.getSession();
          if (sessionData.user && sessionData.user.id) {
            setIsLoggedIn(true);
            setIsProfessional(sessionData.user.professional === '1'); // Check if the user is professional
          } else {
            navigate('/professionaluser'); // Redirect to login if session is not valid
          }
        } catch (error) {
          navigate('/professionaluser'); // Redirect to login if there is an error
        }
      };
      fetchSession();
    }, [navigate]);
  

 
    useEffect(() => {
      const fetchSubscription = async () => {
        try {
          // First ensure the user is a professional before checking subscription status
          if (isProfessional) {
            const { subscription_exists, status, time_left } = await api.getSubscription(); // Fetch subscription info from the backend
            
            // Set subscription based on the existence flag
            setSubscription(subscription_exists);
            
            // If no subscription exists or it has expired, redirect to the subscription page
            if (!subscription_exists || status === 'expired' || (!time_left && status === 'inactive')) {
              setTimeout(() => {
                navigate('/profile/subscription');
              }, 2000); // 2-second delay before navigating
            }
          }
        } catch (error) {
          console.error('Error fetching subscription:', error);
          setSubscription(false); // Set subscription to false in case of an error
        }
      };
    
      // Only fetch subscription if user is a professional
      if (isProfessional) {
        fetchSubscription();
      }
    }, [isProfessional, navigate]);
    
    const openChatBot = () => {
        setIsChatVisible(true); // Open the ChatBot when the icon is clicked
      };
    
      const closeChatBot = () => {
        setIsChatVisible(false); // Close the ChatBot when needed
      };
    
      // Function to close the ChatBot or Dropdown if clicked outside
      const handleOutsideClick = (event) => {
        // Check if the click is outside both the chat window and dropdown
        if (
          chatContainerRef.current && !chatContainerRef.current.contains(event.target)
        ) {
          closeChatBot(); // Close the ChatBot if the click is outside
        }
      };
    
      // Add event listener to detect outside clicks when ChatBot is visible
      useEffect(() => {
        if (isChatVisible) {
          document.addEventListener('mousedown', handleOutsideClick); // Attach event listener
        } else {
          document.removeEventListener('mousedown', handleOutsideClick); // Remove event listener when chat is closed
        }
    
        return () => {
          document.removeEventListener('mousedown', handleOutsideClick); // Cleanup listener on component unmount
        };
      }, [isChatVisible]); // Effect runs only when chat visibility changes
      const handleLanguageChange = (languageCode) => {
        setSelectedLanguage(languageCode);
        setIsDropdownOpen(false); // Close the dropdown after selection
        // Handle additional logic for language change if needed
    };
    return (
        <>
         <nav className=" flex flex-col md:flex-row w-full bg-slate-50 justify-between items-center px-2 py-2 z-[10000] relative">
                {/* Left */}
                <div className="  flex items-center mb-2 md:mb-0">
                    <img onClick={NavigateToBTBHome} src={LOGO2} alt="Logo" className="  w-full object-contain h-10 mr-2 mt-2 hidden md:block" />
                    {/* <div className="  profile-search-input">
                        <FaSearch className="  profile-search-icon" />
                        <input type="text" className="  profile-search" placeholder="Search Jobs and Requests" />
                    </div> */}
                </div>
                {/* center */}
                <div className="  flex gap-2 md:gap-3 mb-4 sm:mb-0">
                {subscription && (
                    <div onClick={NavigateToHome} className="  flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
                        <FaHome size={20} />
                         <p  ClassName="    text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Actualité</p>
                    </div>
                  )}


{subscription ? (         
    <div onClick={NavigateToOffers} className="  flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
        <RiDiscountPercentFill size={20} />
         <p  ClassName="    text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Les pros</p>
    </div>
   ) : null}

                    {/* <div onClick={()=>(setOpenDiv(!openDiv))} className="  flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">

                        < MdAddComment  size={20}/>
                         <p  ClassName="    text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Demandes travaux</p>
                    </div> */}
                    {/* <div onClick={NavigateTosubscription} className="  flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
                        <IoPersonAdd  />
                         <p  ClassName="    text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Tarifs</p>
                    </div> */}
                    <div onClick={NavigateTochat} className="  flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
                        <BiSolidMessageSquare  size={20}/>
                         <p  ClassName="    text-[7px]  sm:text-base font-normal text-black hover:text-[#65a406]">Messagerie</p>
                    </div>
                    {subscription ? (
                        <div onClick={NavigateToforum} className="  flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
                            <MdForum size={20} />
                             <p  ClassName="    text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Forum</p>
                        </div>
                    ) : null}

{!subscription ? (
    <a href="https://btob.btobjob.com/look-for-job.php" className="flex flex-col items-center cursor-pointer hover:text-[#65a406]">
        <MdOutlineMiscellaneousServices size={20} />
        <p className="text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">
            Postuler à un emploi
        </p>
    </a>
) : null}
{!subscription ? (
    <a href="https://btob.btobjob.com/boost.php" className="flex flex-col items-center cursor-pointer hover:text-[#65a406]">
  <MdOutlineMiscellaneousServices size={20} />
        <p className="text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">
        de pos annonce
        </p>
    </a>
) : null}

                    {/* <div onClick={NavigateTowebsite} className="  flex flex-col items-center  hover:cursor-pointer hover:text-[#65a406]">
                        <CgWebsite size={20} />
                         <p  ClassName="    text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Websites</p>
                    </div>   */}
                    {subscription ? (
                    <div onClick={NavigateToAllads} className="  flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
                    <FaTrello size={20} />
                         <p  ClassName="    text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Annonce</p>
                    </div>
                     ) : null}
                    {subscription ? (
                    <div onClick={NavigateToAllacategories} className="  flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
                        <BiSolidCategoryAlt size={20} />
                         <p  ClassName="    text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Vente/location</p>
                    </div>
                          ) : null}
                    <div onClick={NavigateToAllservices} className="  flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
                        <MdOutlineMiscellaneousServices size={20}  />
                         <p  ClassName="    text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Emplois</p>
                    </div>
               
                </div>
                {/* Right */}
                <div className="  flex">
          
                 
                <LanguageDropdown className = 'absolute top-2 right-2'
                            selectedLanguage={selectedLanguage} 
                            onLanguageChange={setSelectedLanguage} // Pass the handler
                        />

        
                   <div className="  flex justify-between border-2 rounded-lg p-1 w-20 mr-5">
                    
                   <div className="  relative">
  <MdOutlineNotifications size={20}
    onClick={() => setopenNotif(!openNotif)}
    className="  text-[#65A406] hover:cursor-pointer"
  />
  {unseenNotifications && (
    <span className="  absolute top-0 right-0 bg-red-500 h-2 w-2 rounded-full" />
  )}
</div>
                <div className='relative' ref={dropdownRef}>
                    <RiContactsLine size={20}
                        className="  text-[#65A406] hover:cursor-pointer"
                        onClick={handleToggle}
                    />
                           {isOpen && (
                        <div
                            className="  absolute md:right-0 mt-5 w-[180px] md:w-[240px] bg-white rounded-md shadow-md z-10"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="menu-button"
                        >
                                   <ul className="  py-7">
                                {/* Close button and other options */}
                                <MdCancel onClick={() => setIsOpen(false)} size={25} color='#75f94c' className="  absolute right-1 top-1" />
                                <li onClick={NavigateToviewprofile}>
                                            <a
                                                href="#"
                                                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <img className="  mr-2 h-7 w-7" src={profileImagePreview || 'default-profile.jpg'} alt="profile" width={24} />
                                                Voir mon profil

                                            </a>
                                        </li>
                                        <li onClick={NavigateToChangePicture}>
                                            <a
                                                href="#"
                                                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <FaRegImage className="  mr-2" color='#75f94c' alt="notification" size={24} />
                                                Mettre à jour l'image

                                            </a>
                                        </li>
                                        {subscription && (
                                        <li onClick={NavigateToCard}>
                                            <a
                                                href="#"
                                                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <FaRegIdCard className="  mr-2" color='#75f94c' alt="notification" size={24} />
                                                carte de visite/flyer

                                            </a>
                                        </li>
                                            )}
                                        {/* <li>
                                            <a
                                                href="#"
                                                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <IoIosNotifications className="  mr-2" color='#75f94c' alt="notification" size={24} />
                                                Notifications

                                            </a>
                                        </li> */}
                                         {subscription && (
                                            <li>
                                            {/* <a
                                                href="#"
                                                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <BiSolidMessageRoundedError className="  mr-2" color='#75f94c' alt="notification" size={24} />
                                                Mes demandes

                                            </a> */}
                                        </li>
                                        )}
                                        <li>
                                            {/* <a
                                                href="view-requests.php"
                                                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <BiSolidMessageRoundedError className="  mr-2" color='#75f94c' alt="notification" size={24} />
                                                consulter votre demande

                                            </a> */}
                                        </li>
                                    
      <div className="  relative inline-block text-left">
        <button
          onClick={toggleDropdown} // Toggle the dropdown on click
          className="  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center"
        >
          <BiSolidMessageRoundedError className="  mr-2" color="#75f94c" alt="notification" size={24} />
          Historique 
          <span className="  ml-auto text-gray-500 ms-3" style={{ transform: 'rotate(90deg)' }}>{'>'}</span>
        </button>

        {/* Dropdown menu positioned to the left */}
        <ul className="  hidden absolute -left-56 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
        {subscription && (
          <li
            onClick={Navigatetoprivate}
            className="  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center"
          >
            <BiSolidMessageRoundedError className="  mr-2" color="#75f94c" alt="notification" size={24} />
            mes demandes privées
          </li> 
        )}
          <li
            onClick={NavigateToViewAllRequests}
            className="  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center"
          >
            <BiSolidMessageRoundedError className="  mr-2" color="#75f94c" alt="notification" size={24} />
            Mes demandes/annonces
          </li>
        
        {    subscription && (
          <li
            onClick={NavigateToViewRequests}
            className="  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center"
          >
            <BiSolidMessageRoundedError className="  mr-2" color="#75f94c" alt="notification" size={24} />
            Demandes reçue
          </li>
         )}
           {    subscription && (
          <li
            onClick={NavigatetoOfferHistory}
            className="  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center"
          >
            <BiSolidMessageRoundedError className="  mr-2" color="#75f94c" alt="notification" size={24} />
            Historique de l'offre
          </li>
         )}
          {subscription && (
          <li
           
            className="  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center"
          >
            <BiSolidMessageRoundedError className="  mr-2" color="#75f94c" alt="notification" size={24} />
            <a href='https://btob.btobjob.com/view-ads.php'>Historique des annonces</a> 
        
          </li>
        
      )}
       {subscription && (
          <li
           
            className="  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center"
          >
            <BiSolidMessageRoundedError className="  mr-2" color="#75f94c" alt="notification" size={24} />
            <a href='https://btob.btobjob.com/view-rental-ads.php'>Historique de location</a> 
        
          </li>
        
      )}
       {subscription && (
          <li
           
            className="  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center"
          >
            <BiSolidMessageRoundedError className="  mr-2" color="#75f94c" alt="notification" size={24} />
            <a href='https://btob.btobjob.com/view-jobs.php'>Historique des emplois</a> 
        
          </li>
        
      )}


{subscription && (
          <li
           
            className="  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center"
          >
            <BiSolidMessageRoundedError className="  mr-2" color="#75f94c" alt="notification" size={24} />
            <a href='https://btob.btobjob.com/view-cv-history.php'>recherche d'emploi</a> 
        
          </li>
        
      )}
        </ul>
      </div>
   



      <div className="  relative inline-block text-left">
        <button
          onClick={toggleDropdown} // Toggle the dropdown on click
          className="  cursor-pointer ps-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row "
        >
          <FaArrowAltCircleUp className="  mr-2" color="#75f94c" alt="notification" size={24} />
          Publier/booster
          <span className="  ml-auto text-gray-500 ms-3" style={{ transform: 'rotate(90deg)' }}>{'>'}</span>
        </button>

        {/* Dropdown menu positioned to the left */}
        <ul className="  hidden absolute -left-56 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">

          <li
            
            className="  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center"
          >
            <FaArrowAltCircleUp className="  mr-2" color="#75f94c" alt="notification" size={24} />
          <a href='https://btob.btobjob.com/boost.php'>de pos annonce</a> 
          </li> 
          {subscription && (
          <li
           
            className="  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center"
          >
            <FaArrowAltCircleUp className="  mr-2" color="#75f94c" alt="notification" size={24} />
            <a href='https://btob.btobjob.com/boost-rental.php'>location ou vente</a> 
        
          </li>
        
      )}
            {subscription && (
          <li
   
            className="  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center"
          >
            <FaArrowAltCircleUp className="  mr-2" color="#75f94c" alt="notification" size={24} />
            <a href='https://btob.btobjob.com/boost-job.php'>emplois</a> 
            
          </li>
             )}
          {subscription && (
            <li onClick={NavigateToAddOffer}>
              <a
                href="#"
                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center"
              >
                <HiSearchCircle className="  mr-2" color="#75f94c" alt="notification" size={24} />
                ajouter une offre
              </a>
            </li>
          )}
           {subscription && (
            <li >
              <a
                href="https://btob.btobjob.com/look-for-job.php"
                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center"
              >
                <HiSearchCircle className="  mr-2" color="#75f94c" alt="notification" size={24} />
                Postuler à un emploi
              </a>
            </li>
          )}
       
        
        </ul>
      </div>
                                      
                                                 
                                      
                                    
                                       
                        
  
                                        {/* <li>
                                            <a
                                                href="#"
                                                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <PiCurrencyCircleDollarFill className="  mr-2" color='#75f94c' alt="notification" size={24} />
                                                Mon Solde

                                            </a>
                                        </li> */}
                                        {/* <li>
                                            <a
                                                href="#"
                                                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <BiSolidCoupon className="  mr-2" color='#75f94c' alt="notification" size={24} />
                                                Mes Coupouns

                                            </a>
                                        </li> */}
                                        <li onClick={NavigateToeditprofile}>
                                            <a
                                            
                                                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <FaInfoCircle className="  mr-2" color='#75f94c' alt="notification" size={24} />
                                                Information personnelles

                                            </a>
                                        </li>

                                        <li onClick={()=>(setOpenShare(!openShare))}>
                                            <a
                                                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <IoMdShare className="  mr-2" color='#75f94c' alt="notification" size={24} />
                                                Partager

                                            </a>
                                        </li>
                                        <li onClick={NavigatetoContact}>
                                <a 
                                    href=""
                                    className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                                >
                                    <FaFlag
                                    className="  mr-2 flex-shrink-0" 
                                    color='#75f94c' 
                                    alt="notification" 
                                    size={24} 
                                    />
                                    <span className="  flex-grow">rapport</span>
                                </a>
                                </li>
                                <li>
                                <a href='https://btob.btobjob.com/delete-profile.php' 
                                   
                                    className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                                >
                                    <FaExclamationTriangle 
                                    className="  mr-2 flex-shrink-0" 
                                    color='#75f94c' 
                                    alt="notification" 
                                    size={24} 
                                    />
                                    <span className="  flex-grow">Supprimer le profil</span>
                                </a>
                                </li>
                                <li>
                                <button onClick={handleLogout} 
                                   
                                    className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                                >
                                    <FaSignOutAlt
                                    className="  mr-2 flex-shrink-0" 
                                    color='#75f94c' 
                                    alt="notification" 
                                    size={24} 
                                    />
                                    <span className="  flex-grow">Déconnexion</span>
                                </button>
                                </li>

                                        {/* <li>
                                            <a
                                                href="#"
                                                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <MdBarChart className="  mr-2" color='#75f94c' alt="notification" size={24} />
                                                Mes Reports

                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

            </nav>
   {/* Render the ChatBotIcon at the bottom of the page */}
   <ChatBotIcon onClick={openChatBot} />

{/* Conditionally render the ChatBot based on isChatVisible */}
{isChatVisible && (
  <div
    ref={chatContainerRef} // Reference to ChatBot container
    className="  fixed bottom-0 right-0 w-full h-3/4 p-4 bg-white shadow-lg md:w-1/3 md:h-1/2 z-[9999]"
  >
    <ChatBot onClose={closeChatBot} /> {/* Pass the onClose handler */}
  </div>
)}
  {openNotif && (
  <div className="  absolute top-10 right-10 z-100">
    <Notifications boolean={openNotif} returnState={setopenNotif} /> {/* Fixed: use setopenNotif */}
  </div>
)}
            <PublicOrPrivate toggleboolean={openDiv} updateState={getDivState}/>
            <ProfileShare boolean={openShare}/>
          

        </>
    )
}


  

export default NavbarProfile;