import React, { useState, useEffect } from 'react';
import { FaArrowLeft } from "react-icons/fa";
import { IoShareSocialOutline } from "react-icons/io5";
import { useNavigate, useLocation } from 'react-router-dom';
import MessageModal from '../OfferPage/MessageModal';
import axios from 'axios';
import Share from '../Share';
import api from '../../services/api'; // Import your API service

const API_URL = 'https://btob.btobjob.com';

const AdsDetails = () => {
  const [ad, setAd] = useState(null); // State to hold ad details
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [openShareModal, setOpenShareModal] = useState(false); // State to control share modal
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Get ad_id from query params
  const query = new URLSearchParams(location.search);
  const adId = query.get('ad_id');

  useEffect(() => {
    const fetchAdDetails = async () => {
      if (!adId) {
        setError('No ad ID provided');
        setLoading(false);
        return;
      }
      try {
        setLoading(true);
        const response = await axios.get(`${API_URL}/fetch-ad-details.php`, {
          params: { ad_id: adId },
          withCredentials: true,
        });

        if (response.data && response.data.ad) {
          setAd(response.data.ad);
        } else {
          setError('Ad not found');
        }
      } catch (error) {
        console.error('Error fetching ad details:', error);
        setError('Error fetching ad details');
      } finally {
        setLoading(false);
      }
    };

    fetchAdDetails();
  }, [adId]);

  const handleSendMessage = async (message) => {
    if (!ad?.user_id) {
        console.error("User ID not found for the ad");
        return;
    }

    if (!message.trim()) {
        console.error("Message is empty");
        return;
    }

    try {
        const fullMessage = ad.title ? `(Replying to ad: ${ad.title})\n\n${message}` : message;

        const response = await api.sendMessagee({
            receiverId: ad.user_id,
            message: fullMessage
        });
        console.log("Message sent response:", response);
        navigate(`/profile/chat`);
    } catch (error) {
        console.error("Error sending message:", error);
    }
};


  const openMessageModal = () => {
    setIsMessageModalOpen(true);
  };

  // Handle redirect to profile
  const viewProfile = () => {
    if (ad && ad.user_id) {
      navigate(`/profile/specificUser?professional_id=${ad.user_id}`);
    }
  };

  const currentUrl = window.location.href;

  return (
    <div className="max-w-4xl mx-auto p-4 mt-10">
      {/* Back Button */}
      <button
        onClick={() => navigate(-1)}
        className="mb-6 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
      >
        <FaArrowLeft className="inline mr-2" /> Back
      </button>

      {/* Loading and Error States */}
      {loading && <p className="text-center text-gray-500">Loading...</p>}
      {error && <p className="text-center text-red-500">{error}</p>}
      
      {/* Ad Details */}
      {ad && (
        <div className="bg-white shadow-md rounded-lg p-4">
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setOpenShareModal(true)}
              className="flex items-center px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition"
            >
              <IoShareSocialOutline className="mr-2" /> Share
            </button>
            <button
              onClick={openMessageModal}
              className="ml-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Send Message
            </button>
          </div>

          {/* Professional's Profile Picture and Name at the Top */}
          <div className="flex items-center justify-center mb-6">
            {ad.ProfilePicture && (
              <img
                src={ad.ProfilePicture}
                alt="Profile Picture"
                className="w-24 h-24 object-cover rounded-full mr-4"
              />
            )}
          </div>
          
          <h2 className="text-xl font-bold mb-2">Posted by:</h2>
          <h1 className="text-3xl font-bold">{ad.Name}</h1>
          <button
            onClick={viewProfile}
            className="mt-2 px-2 py-1 bg-lime-500 text-white rounded hover:bg-lime-600 mb-3"
          >
            View Profile
          </button>
          
          {/* Display multiple images in a grid layout */}
          {ad.images && ad.images.length > 0 && (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
              {ad.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Ad Image ${index + 1}`}
                  className="w-full h-60 object-cover rounded-md"
                />
              ))}
            </div>
          )}

          <h2 className="text-2xl font-bold mb-2">{ad.title}</h2>
          <p className="text-lg text-gray-700 mb-4">{ad.description}</p>
          <p className="text-sm text-gray-500">Location: {ad.location}</p>
          <p className="text-sm text-gray-500">Profession: {ad.profession}</p>
        </div>
      )}

      {/* Share Modal */}
      {openShareModal && (
        <Share
          profileUrl={currentUrl}
          closeModal={() => setOpenShareModal(false)}
        />
      )}

      {/* Message Modal */}
      {isMessageModalOpen && (
        <MessageModal
          isOpen={isMessageModalOpen}
          onClose={() => setIsMessageModalOpen(false)}
          onSend={handleSendMessage}
        />
      )}
    </div>
  );
};

export default AdsDetails;
