import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoEllipsisVertical, IoWarningOutline } from "react-icons/io5"; // Icons for 3 dots and report
import api from '../../services/api';

const CommentList = ({ comments }) => {
  if (!Array.isArray(comments) || comments.length === 0) {
    return <p>No comments available</p>;
  }

  return (
    <div className="mt-4 space-y-4">
      {comments.map((comment, index) => (
        <CommentItem key={index} comment={comment} />
      ))}
    </div>
  );
};

const CommentItem = ({ comment }) => {
  const [replies, setReplies] = useState([]);
  const [newReply, setNewReply] = useState('');
  const [menuOpen, setMenuOpen] = useState(false); // For the 3 dots menu
  const navigate = useNavigate();

  useEffect(() => {
    const fetchReplies = async () => {
      try {
        const repliesData = await api.fetchReplies(comment.id);
        setReplies(Array.isArray(repliesData) ? repliesData : []);
      } catch (error) {
        console.error("Error fetching replies:", error);
        setReplies([]);
      }
    };

    fetchReplies();
  }, [comment.id]);

  const handleReplySubmit = () => {
    api.submitReply({ comment_id: comment.id, reply: newReply })
      .then(response => {
        if (response && response.success) {
          const newReplyObject = {
            id: response.reply_id,
            reply: newReply,
            professional_name: response.professional_name,
            profile_picture: response.profile_picture,
          };
          setReplies(prevReplies => [...prevReplies, newReplyObject]);
          setNewReply('');
        } else {
          console.error('Error submitting reply:', response ? response.message : "No response from server");
        }
      })
      .catch(error => {
        console.error('Error submitting reply:', error);
      });
  };

  const handleViewProfile = (reply) => {
    if (reply && reply.user_id) {
      navigate(`/profile/specificUser?professional_id=${reply.user_id}`);
    }
  };

  const handleViewCommentProfile = () => {
    if (comment && comment.user_id) {
      navigate(`/profile/specificUser?professional_id=${comment.user_id}`);
    }
  };

  const handleReport = () => {
    // Handle the reporting logic here (e.g., call an API to report the comment)
    console.log("Reported comment with ID:", comment.id);
    setMenuOpen(false); // Close the menu after reporting
  };

  return (
    <div className="p-4 border rounded-lg shadow-sm bg-white flex flex-col items-start relative">
      <div className="flex items-start w-full">
        {comment.profile_picture ? (
          <img
            src={comment.profile_picture}
            alt={`${comment.professional_name}'s profile`}
            className="w-10 h-10 rounded-full mr-3"
          />
        ) : (
          <div className="w-10 h-10 bg-gray-300 rounded-full mr-3" />
        )}
        <div className="flex-1">
          <p onClick={handleViewCommentProfile} className="cursor-pointer font-semibold">
            {comment.professional_name || 'Anonymous'}
          </p>
          <p className="text-gray-700">{comment.comment}</p>
        </div>
        {/* 3 Dots Button */}
        <button onClick={() => setMenuOpen(!menuOpen)} className="text-gray-500">
          <IoEllipsisVertical size={20} />
        </button>
      </div>

      {/* Options Menu */}
      {menuOpen && (
        <div className="absolute right-4 top-10 bg-white border rounded-md shadow-lg p-2">
          <button
            onClick={handleReport}
            className="flex items-center px-4 py-2 hover:bg-gray-100 w-full text-left text-red-500"
          >
            <IoWarningOutline className="mr-2" /> Report
          </button>
        </div>
      )}

      {/* Replies Section */}
      <div className="ml-10 mt-2 w-full">
        {Array.isArray(replies) && replies.length > 0 ? (
          replies.map((reply, index) => (
            <div key={index} className="flex items-start my-2">
              {reply.profile_picture ? (
                <img
                  src={reply.profile_picture}
                  alt={`${reply.professional_name}'s profile`}
                  className="w-8 h-8 rounded-full mr-2"
                />
              ) : (
                <div className="w-8 h-8 bg-gray-300 rounded-full mr-2" />
              )}
              <div>
                <p 
                  onClick={() => handleViewProfile(reply)} 
                  className="cursor-pointer font-semibold text-sm hover:underline"
                >
                  {reply.professional_name || 'Anonymous'}
                </p>
                <p className="text-gray-700 text-sm">{reply.reply}</p>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-500 text-sm">No replies available</p>
        )}
      </div>

      {/* Reply Input */}
      <div className="ml-10 mt-2 w-full">
        <textarea
          className="w-full p-2 border rounded-md"
          rows="2"
          value={newReply}
          onChange={e => setNewReply(e.target.value)}
          placeholder="Write a reply..."
        />
        <button
          className="mt-1 px-4 py-1 bg-lime-500 text-white rounded-md text-sm"
          onClick={handleReplySubmit}
        >
          Reply
        </button>
      </div>
    </div>
  );
};

export default CommentList;
