import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "../home";
import FaqsPage from "../pages/Faqs";
import ContactPage from "../pages/Contact";
import AboutPage from "../pages/About";
import LogIn from "../signup/login/login";
import IndividualSignup from "../signup/login/Individualsign";
import ProfessionalSignup from "../signup/login/Professionalsignup";
import ProfileUser from "../afterlogin/Profile";
import Choice from "../homecomponents/Choice";
import LoginPro from "../signup/login/login-pro"; // Updated import
import BlogPage from "../pages/blogs";
function AppRouter() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/Profile/*" element={<ProfileUser />} />
                <Route path="/faqs" element={<FaqsPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/individualsignup" element={<IndividualSignup />} />
                <Route path="/login" element={<LogIn />} />
                <Route path="/professionaluser" element={<ProfessionalSignup />} />
                <Route path="/professionaluser" element={<Choice />} />
                <Route path="/login-pro" element={<LoginPro />} /> 
                <Route path="/BlogPage" element={<BlogPage />} /> 
            </Routes>
        </BrowserRouter>
    );
}

export default AppRouter;
