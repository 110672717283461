import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Notifications = ({ boolean, returnState }) => {
    const [localToggle, setLocalToggle] = useState(boolean);
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        setLocalToggle(boolean);
    }, [boolean]);

    useEffect(() => {
        if (localToggle) {
            // Fetch notifications when the modal is toggled
            axios.get('https://btob.btobjob.com/notifications.php', { withCredentials: true })
                .then((response) => {
                    if (response.data.success) {
                        setNotifications(response.data.notifications);
                    } else {
                        console.log('Error fetching notifications:', response.data.message);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }, [localToggle]);

    const handleClose = () => {
        setLocalToggle(!localToggle);
        returnState(!localToggle);
    };

    const handleRedirect = (notification) => {
        // Redirect based on the notification type and use related_item_id
        switch (notification.notification_type) {
            case 'like':
            case 'comment':
                window.location.href = `https://btob.btobjob.com/profile/posts/${notification.related_item_id}`;
                break;
            case 'cv_upload':
                window.location.href = 'https://btob.btobjob.com/view-jobs.php';
                break;
            case 'private_request':
                window.location.href = 'https://btob.btobjob.com/profile/PrivateRequest';
                break;
            default:
                break;
        }
    };

    const handleClearNotifications = () => {
        axios.post('https://btob.btobjob.com/clear_notifications.php', {}, { withCredentials: true })
            .then((response) => {
                if (response.data.success) {
                    // Clear notifications from the state
                    setNotifications([]);
                } else {
                    console.log('Error clearing notifications:', response.data.message);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    return (
        <div className={`${localToggle ? 'flex' : 'hidden'} items-center justify-center fixed inset-0 z-[99999] bg-black/50`}>
            <div className="  lg:w-2/5 sm:w-3/5 w-11/12 bg-gray-100 rounded-xl mx-auto border p-2 shadow-sm h-[500px] overflow-y-scroll">
                <div className="  inline-flex items-center justify-between w-full">
                     <h3  ClassName="    font-bold text-sm sm:text-2xl text-gray-800">Notifications</h3>
                    <button
                        onClick={handleClose}
                        className="  inline-flex text-xs sm:text-sm bg-white px-2 sm:px-3 py-2 text-lime-600 items-center rounded font-medium shadow border focus:outline-none transform active:scale-75 transition-transform duration-700 hover:bg-lime-600 hover:text-white hover:-translate-y-1 hover:scale-110"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="  h-4 w-4 mr-1 sm:mr-2" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                        Close
                    </button>
                </div>

                {/* Display notifications */}
                {notifications.length > 0 ? (
                    notifications.map((notification) => (
                        <div key={notification.id} className="  mt-2 px-6 py-4 bg-white rounded-lg shadow w-full">
                            <div className="  inline-flex items-center justify-between w-full">
                                 <h3  ClassName="    font-bold text-base text-gray-800">
                                    {notification.message}
                                </h3>
                                 <p  ClassName="    text-xs text-gray-500">
                                    {new Date(notification.timestamp).toLocaleString()}
                                </p>
                            </div>
                            {/* Display View Post button based on notification type */}
                            {(notification.notification_type === 'like' || notification.notification_type === 'comment' ||
                              notification.notification_type === 'cv_upload' || notification.notification_type === 'private_request') && (
                                <button
                                    onClick={() => handleRedirect(notification)}
                                    className="  inline-flex text-sm bg-lime-500 text-white px-4 py-2 mt-2 items-center rounded font-medium shadow hover:bg-blue-600 transition-transform duration-300"
                                >
                                    {notification.notification_type === 'cv_upload' || notification.notification_type === 'private_request' ? 'View Request' : 'View Post'}
                                </button>
                            )}
                        </div>
                    ))
                ) : (
                     <p  ClassName="    mt-4 text-center text-gray-500">No notifications found</p>
                )}

                <button
                    onClick={handleClearNotifications}
                    className="  inline-flex text-sm bg-white justify-center px-4 py-2 mt-12 w-full text-red-500 items-center rounded font-medium shadow border focus:outline-none transform active:scale-75 transition-transform duration-700 hover:bg-red-500 hover:text-white hover:-translate-y-1 hover:scale-110"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="  h-4 w-4 mr-2 sm:mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    Clear All Notifications
                </button>
            </div>
        </div>
    );
};

export default Notifications;
