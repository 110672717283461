import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";

const ViewAllRequests = () => {
  const [requests, setRequests] = useState([]);
  const [ads, setAds] = useState([]); // New state for ads
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalRequests, setTotalRequests] = useState(0); // Total number of requests (for pagination)
  const [error, setError] = useState(null); // Error state for handling API issues

  const navigate = useNavigate();
  const limit = 10; // Number of requests per page

  useEffect(() => {
    const fetchRequestsAndAds = async () => {
      setLoading(true);  // Start the loading spinner
      setError(null);    // Reset the error state
      try {
        const response = await api.getRequests(page, limit); // Fetch requests and ads
        setRequests(response.requests || []); // Handle potential empty data for requests
        setTotalRequests(response.totalRequests || 0);        // Total number of requests for pagination
        setAds(response.ads || []);  // Handle potential empty data for ads
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load data. Please try again later.");
      } finally {
        setLoading(false); // Stop the loading spinner
      }
    };

    fetchRequestsAndAds();
  }, [page]); // Trigger this effect when the page changes

  const handleRequestClick = (professionalId) => {
    navigate(`/profile/specificUser?professional_id=${professionalId}`);
  };

  const handleNextPage = () => {
    if (page * limit < totalRequests) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    setPage(prevPage => Math.max(prevPage - 1, 1));
  };

  return (
    <div className="  max-w-2xl mx-auto p-8 bg-white shadow-lg rounded-lg mt-10">
       <h2  ClassName="    text-3xl font-bold mb-6 text-center text-gray-700">mes demandes publiques</h2>

      {loading ? (
         <p  ClassName="    text-center">Loading...</p>
      ) : error ? (
         <p  ClassName="    text-center text-red-500">{error}</p> // Display error message if present
      ) : (
        <>
          {/* Requests Section */}
          <div className="  space-y-6">
            {requests.length === 0 ? (
               <p  ClassName="    text-center">No requests found.</p>
            ) : (
              requests.map((request) => (
                <div key={request.id} className="  p-4 border border-gray-300 rounded-lg">
                   <h3  ClassName="    text-lg  mb-2"><b>Description</b>{request.request}</h3>
                  
                  <div className="  flex justify-between mb-4">
                    <div className="  flex flex-col">
                      <span className="  font-semibold">Category:</span>
                      <span>{request.category}</span>
                    </div>
                    <div className="  flex flex-col">
                      <span className="  font-semibold">Price:</span>
                      <span>{request.price} €</span>
                    </div>
                    <div className="  flex flex-col">
                      <span className="  font-semibold">Address:</span>
                      <span>{request.address}</span>
                    </div>
                    <div className="  flex flex-col">
                      <span className="  font-semibold">Date:</span>
                      <span>{request.time}</span>
                    </div>
                  </div>
                 
              
              
              
                  <div className="  flex gap-4">
                    {request.file1 && (
                      <img src={`https://btob.btobjob.com/${request.file1}`} alt="File 1" className="  w-20 h-20 object-cover rounded-lg" />
                    )}
                    {request.file2 && (
                      <img src={`https://btob.btobjob.com/${request.file2}`} alt="File 2" className="  w-20 h-20 object-cover rounded-lg" />
                    )}
                    {request.file3 && (
                      <img src={`https://btob.btobjob.com/${request.file3}`} alt="File 3" className="  w-20 h-20 object-cover rounded-lg" />
                    )}
                  </div>
                  {/* <button
                    onClick={() => handleRequestClick(request.user_id)} 
                    className="  bg-lime-500 text-white px-4 py-2 mt-4 rounded hover:bg-blue-600 transition duration-300"
                  >
                    View Profile
                  </button> */}
                </div>
              ))
            )}
          </div>

          {/* Ads Section */}
           <h2  ClassName="    text-3xl font-bold mt-10 mb-6 text-center text-gray-700">mes annonces</h2>
          <div className="  space-y-6">
            {ads.length === 0 ? (
               <p  ClassName="    text-center">No ads found.</p>
            ) : (
              ads.map((ad) => (
                <div key={ad.id} className="  p-4 border border-gray-300 rounded-lg">
                   <h3  ClassName="    text-lg font-bold mb-2">{ad.title}</h3>
                  <p>{ad.description}</p>
                  <div className="  flex justify-between">
                    <span><b>Profession:</b>{ad.profession}</span>
                    <span><b>Localisation:</b>{ad.location}</span>
                
                  </div>
                </div>
              ))
            )}
          </div>
        </>
      )}

      {/* Pagination */}
      <div className="  flex justify-between mt-6">
        <button 
          onClick={handlePrevPage} 
          className="  bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition duration-300"
          disabled={page === 1}
        >
          Previous
        </button>
        <button 
          onClick={handleNextPage} 
          className="  bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition duration-300"
          disabled={page * limit >= totalRequests}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ViewAllRequests;
