import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../services/api"; // Adjust the path as necessary

const AddOffer = () => {
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState(""); // State for selected category
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false); // State for loading

  const navigate = useNavigate();

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // If already loading, do not submit
    if (loading) return;

    // Start loading
    setLoading(true);
    setError("");
    setSuccess("");

    const formData = new FormData();
    formData.append("description", description);
    formData.append("image", image);
    formData.append("category", category); // Append category to form data

    try {
      const response = await api.addOffer(formData); // Use the API module here
      setSuccess("Offer added successfully!");
      setDescription("");
      setImage(null);
      setCategory(""); // Clear category selection after successful submission
    } catch (error) {
      setError("Error adding offer. Please try again.");
      console.error(error);
    } finally {
      // Stop loading
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate("/profile"); // Navigate back to the profile page or another suitable page
  };

  // Array of category options in French
  const categoryOptions = [
    "Agent immobilier",
    "Architecte/décorateur",
    "Autre Maçon",
    "Carrossier/Peintre",
    "Charpentier/Couvreur",
    "Chauffagiste/frigoriste",
    "Chauffeur/Livreur",
    "Commercial",
    "Community manager",
    "Conciergerie",
    "Cuisiniste",
    "Décoration",
    "Déménagement",
    "Électricien",
    "Entreprise de sécurité",
    "Facadier",
    "Formateur/formatrice",
    "Garde d’animaux",
    "Informaticien/développeur",
    "Jardinier",
    "Maintenance/Bricolage",
    "Maçonnerie",
    "Manutention/débarras",
    "Mécanicien",
    "Menuisier",
    "Métier de secrétariat",
    "Nettoyage automobile",
    "Nettoyage de vitres",
    "Organisateur d’évènement",
    "Paysagiste/jardinier",
    "Peintre",
    "Peintre/décorateur",
    "Plaquiste/plâtrier",
    "Plomberie",
    "Poseur de piscine",
    "Réparateur électronique",
    "Restauration",
    "Serrurier/dépannage",
    "Services à la personne",
    "Terrassement"
  ];
  

  return (
    <div className="  max-w-2xl mx-auto p-8 bg-white shadow-lg rounded-lg mt-10">
       <h2  ClassName="    text-3xl font-bold mb-6 text-center text-gray-700">Ajouter une offre</h2>
      {error && <div className="  text-red-500 mb-4">{error}</div>}
      {success && <div className="  text-green-500 mb-4">{success}</div>}
      <form onSubmit={handleSubmit} className="  space-y-6">
        <div className="  flex flex-col">
          <label htmlFor="description" className="  mb-2 text-lg font-medium text-gray-600">
            Description
          </label>
          <textarea
            id="description"
            value={description}
            onChange={handleDescriptionChange}
            className="  p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows="5"
            required
          />
        </div>
        <div className="  flex flex-col">
          <label htmlFor="image" className="  mb-2 text-lg font-medium text-gray-600">
            Image
          </label>
          <input
            type="file"
            id="image"
            onChange={handleImageChange}
            accept="image/*"
            className="  p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div className="  flex flex-col">
          <label htmlFor="category" className="  mb-2 text-lg font-medium text-gray-600">
            Catégorie
          </label>
          <select
            id="category"
            value={category}
            onChange={handleCategoryChange}
            className="  p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          >
            <option value="">Sélectionnez une catégorie</option>
            {categoryOptions.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
        <div className="  flex gap-4">
          <button
            type="submit"
            className={`w-full py-2 rounded-lg shadow-md text-white transition duration-300 ${
              loading ? "bg-gray-400 cursor-not-allowed" : "bg-lime-500 hover:bg-blue-600"
            }`}
            disabled={loading} // Disable button when loading
          >
            {loading ? "En cours..." : "Soumettre"} {/* Show loading text */}
          </button>
          <button
            type="button"
            onClick={handleCancel}
            className="  w-full bg-gray-500 text-white py-2 rounded-lg shadow-md hover:bg-gray-600 transition duration-300"
          >
            Annuler
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddOffer;
