// src/components/Terms.jsx
import React from 'react';

function Terms({ onClose }) {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full mx-4 overflow-y-auto h-[80vh]">
                <h2 className="text-2xl font-bold mb-4">CONDITIONS GENERALES D’UTILISATION ET DE VENTE DE BTOB JOB</h2>

                <h3 className="text-lg font-bold mt-4">ARTICLE 1 - Désignation du Vendeur</h3>
                <p className="mt-4">
                    BTOB JOB<br />
                    4 rue de la Fabrique<br />
                    21560 COUTERNON<br />
                    RCS DIJON n° 929 286 078<br />
                    Téléphone : …<br />
                    Mail : btobjob2024@gmail.com<br />
                    Site internet : <a href="https://btobjob.com/" className="text-blue-500">https://btobjob.com/</a>
                </p>

                <h3 className="text-lg font-bold mt-4">ARTICLE 2 - Champ d'application des Conditions générales de vente</h3>
                <p className="mt-4">
                    Les présentes Conditions Générales de Vente s'appliquent entre les clients professionnels et le vendeur sus désigné. Le client souscrit à un abonnement sur le présent site internet, pour bénéficier des prestations / services suivants, sans restriction ni réserve de :
                </p>
                <ul className="list-disc list-inside ml-4">
                    <li>Mise en relation entre professionnels : Cela permet aux clients / utilisateurs de se connecter avec d'autres professionnels, que ce soit pour des collaborations, des partenariats, des sous-traitances ou des projets communs.</li>
                    <li>Poster des annonces pour la vente de matériels : Les abonnés peuvent proposer des produits ou services à la vente en publiant des annonces spécifiques.</li>
                    <li>Poster des annonces pour la mise à disposition de services de location : Les abonnés peuvent proposer des produits ou services à la location en publiant des annonces spécifiques.</li>
                    <li>Poster des annonces pour un besoin de sous-traitance : Si un utilisateur a un besoin de sous-traitance dans un domaine spécifique, il peut publier une annonce pour trouver des partenaires capables de réaliser ce travail.</li>
                    <li>Réponse aux annonces publiées par des particuliers : Les abonnés peuvent consulter et répondre à des annonces créées par des particuliers. Ces annonces peuvent concerner différents types de services ou demandes.</li>
                    <li>Mise à la disposition de moyens de paiement afin de garantir le paiement de la prestation.</li>
                </ul>
                <p className="mt-4">
                    Les Services proposés par BTOB JOB ("le Vendeur") aux consommateurs et Clients non professionnels ("Les Clients ou le Client") (Vendeur et Client étant également désignés comme « les Parties » ou « une Partie ») sont disponibles sur le site Internet <a href="https://btobjob.com/" className="text-blue-500">https://btobjob.com/</a>.
                    Elles précisent, notamment, les conditions d'utilisation du site du Vendeur, de passation de commande, de paiement et de fourniture des Services commandés par les Clients.
                </p>
                <p className="mt-4">
                    Les caractéristiques principales des Services, regroupant l'ensemble des informations substantielles requises par la réglementation applicable, sont présentées sur le site internet. Le Client est tenu d'en prendre connaissance avant toute passation de commande. Le choix et l'achat d'un Service est de la seule responsabilité du Client.
                </p>
                <p className="mt-4">
                    Les Conditions Générales de Vente sont systématiquement communiquées à tout Client préalablement à la conclusion du contrat de fourniture des Services et prévaudront, le cas échéant, sur toute autre version ou tout autre document contradictoire. Elles sont accessibles à tout moment sur le site Internet et prévaudront, le cas échéant, sur toute autre version ou tout autre document contradictoire.
                </p>
                <p className="mt-4">
                    Ces conditions s'appliquent à l'exclusion de toutes autres conditions, notamment celles applicables pour d'autres circuits de commercialisation des Services. Elles sont susceptibles d'être complétées par des conditions particulières, énoncées sur le site Internet, avant toute transaction avec le Client.
                </p>
                <p className="mt-4">
                    Sauf preuve contraire, les données enregistrées dans le système informatique du Vendeur constituent la preuve de l'ensemble des transactions conclues avec le Client.
                </p>
                <p className="mt-4">
                    Conformément à la réglementation en vigueur, le Client dispose, à tout moment, d'un droit d'accès, de rectification, d'opposition, d'effacement et de portabilité de l'ensemble de ses données personnelles en écrivant, par courrier et en justifiant de son identité, à BTOB JOB, 4 rue de la Fabrique 21560 COUTERNON.
                </p>
                <p className="mt-4">
                    Le Client déclare avoir pris connaissance des présentes Conditions Générales de Ventes et les avoir acceptées en cochant la case prévue à cet effet avant la mise en œuvre de la procédure de commande en ligne ainsi que des conditions générales d'utilisation du site internet.
                </p>
                <p className="mt-4">
                    La validation de la commande de Services par le Client vaut acceptation sans restriction ni réserve des présentes Conditions Générales de Vente. Ces Conditions Générales de Vente pouvant faire l'objet de modifications ultérieures, la version applicable à l'achat du Client est celle en vigueur au jour de la conclusion du contrat.
                </p>
                <p className="mt-4">
                    Le Client reconnaît avoir la capacité requise pour contracter et acquérir les Services proposés sur le site internet.
                </p>
                <h3 className="text-lg font-bold mt-4">ARTICLE 3 - Commandes</h3>
<p className="mt-4">
    Le Client sélectionne sur le site les Services qu'il désire commander, selon les modalités suivantes : 
</p>
<p className="mt-4">
    L’utilisateur de <a href="https://btobjob.com/" className="text-blue-500">https://btobjob.com/</a> doit s’abonner, après avoir créé un compte utilisateur, pour un montant mensuel de 20 euros TTC afin qu’il puisse bénéficier des services proposés par celui-ci.
</p>
<p className="mt-4">
    Ensuite, l’utilisateur professionnel répondra aux annonces publiées afin de réaliser tel ou telle prestation. À l’inverse, un client pourra poster des annonces, dans lesquelles il sollicitera des prestations de la part de professionnels (des utilisateurs ayant des compétences spécifiques). Une plateforme où l’offre et la demande se rencontrent de manière efficace et accélérée.
</p>
<p className="mt-4">
    Les informations contractuelles sont présentées en langue française et font l'objet d'une confirmation au plus tard au moment de la validation de la commande par le Client.
</p>
<p className="mt-4">
    L'enregistrement d'une commande sur le site du Vendeur est réalisé lorsque le Client accepte les présentes Conditions Générales de Vente en cochant la case prévue à cet effet et valide sa commande. Le Client a la possibilité de vérifier le détail de sa commande, son prix total et de corriger d'éventuelles erreurs avant de confirmer son acceptation (article 1127-2 du Code Civil).
</p>
<p className="mt-4">
    Cette validation implique l'acceptation de l'intégralité des présentes Conditions Générales de Vente et constitue la preuve du contrat de vente. Il appartient au Client de vérifier l'exactitude de la commande et de signaler immédiatement toute erreur.
</p>
<p className="mt-4">
    La vente de Services ne sera considérée comme définitive qu'après l'envoi au Client de la confirmation de l'acceptation de la commande par le Vendeur, par courrier électronique et après encaissement par celui-ci de l'intégralité du prix ou de l'acompte dû.
</p>
<p className="mt-4">
    Toute commande passée sur le site internet <a href="https://btobjob.com/" className="text-blue-500">https://btobjob.com/</a> constitue la formation d'un contrat conclu à distance entre le Client et le Vendeur.
</p>
<p className="mt-4">
    Le Vendeur se réserve le droit d'annuler ou de refuser toute commande d'un Client avec lequel il existerait un litige relatif au paiement d'une commande antérieure.
</p>
<h3 className="text-lg font-bold mt-4">ARTICLE 4 - Tarifs</h3>
<p className="mt-4">
    Les Services proposés par le Vendeur sont fournis aux tarifs en vigueur, tels que sur le site internet <a href="https://btobjob.com/" className="text-blue-500">https://btobjob.com/</a> selon le devis établi par le Vendeur, lors de l'enregistrement de la commande par le Vendeur. Les prix sont exprimés en Euros, HT et TTC.
</p>
<p className="mt-4">
    Les tarifs tiennent compte d'éventuelles réductions qui seraient consenties par le Vendeur dans les conditions précisées sur le site internet <a href="https://btobjob.com/" className="text-blue-500">https://btobjob.com/</a>.
</p>
<p className="mt-4">
    Ces tarifs sont fermes et non révisables pendant leur période de validité, telle qu'indiqué sur le site internet, le Vendeur se réservant le droit, hors cette période de validité, de modifier les prix à tout moment.
</p>
<p className="mt-4">
    Ils ne comprennent pas les frais de traitement et de gestion, qui sont facturés en supplément, dans les conditions indiquées sur le site internet <a href="https://btobjob.com/" className="text-blue-500">https://btobjob.com/</a> et calculés préalablement à la passation de la commande.
</p>
<p className="mt-4">
    Le paiement demandé au Client correspond au montant total de l'achat, y compris ces frais.
</p>
<p className="mt-4">
    Une facture est établie par le Vendeur et remise au Client lors de la fourniture des Services commandés.
</p>
<h3 className="text-lg font-bold mt-4">ARTICLE 6 - Fourniture des Services</h3>
<p className="mt-4">
    Les Services commandés par le Client, qui comprennent les prestations :
</p>
<ul className="list-disc list-inside ml-4">
    <li>Mise en relation entre professionnels : Cela permet aux clients / utilisateurs de se connecter avec d'autres professionnels, que ce soit pour des collaborations, des partenariats, des sous-traitances ou des projets communs.</li>
    <li>Poster des annonces pour la vente de matériels : Les abonnés peuvent proposer des produits ou services à la vente en publiant des annonces spécifiques.</li>
    <li>Poster des annonces pour la mise à disposition de services de location : Les abonnés peuvent proposer des produits ou services à la location en publiant des annonces spécifiques.</li>
    <li>Poster des annonces pour un besoin de sous-traitance : Si un utilisateur a un besoin de sous-traitance dans un domaine spécifique, il peut publier une annonce pour trouver des partenaires capables de réaliser ce travail.</li>
    <li>Réponse aux annonces publiées par des particuliers : Les abonnés peuvent consulter et répondre à des annonces créées par des particuliers. Ces annonces peuvent concerner différents types de services ou demandes.</li>
    <li>Mise à la disposition de moyens de paiement afin de garantir le paiement de la prestation.</li>
</ul>
<p className="mt-4">
    Ces services seront fournis selon les modalités suivantes, un utilisateur client postera une annonce concernant une prestation de service, par la suite, un prestataire (aussi utilisateur de la plateforme) répondra à l’annonce afin de réaliser la prestation (sous-traitance, location…). Cela, dans un délai maximum de 30 jours à compter de la validation définitive de la commande du Client, dans les conditions prévues aux présentes Conditions Générales de Vente, à l'adresse indiquée par le Client lors de sa commande sur le site Internet <a href="https://btobjob.com/" className="text-blue-500">https://btobjob.com/</a>.
</p>
<p className="mt-4">
    Le Vendeur s'engage à faire ses meilleurs efforts pour fournir les Services commandés par le Client, dans le cadre d'une obligation de moyens et dans les délais ci-dessus précisés.
</p>
<p className="mt-4">
    Si les Services commandés n'ont pas été fournis dans un délai de 30 jours après la date indicative ci-dessus précisée, pour toute autre cause que la force majeure ou le fait du Client, celui-ci pourra notifier au Vendeur, dans les conditions prévues à l'article L 216-6 du Code de la consommation :
</p>
<ul className="list-disc list-inside ml-4">
    <li>soit la suspension du paiement de tout ou partie du prix jusqu'à ce que le Vendeur s'exécute, dans les conditions prévues aux articles 1219 et 1220 du code civil (exception d'inexécution),</li>
    <li>soit la résolution de la vente, après avoir mis le Vendeur en demeure de s'exécuter dans un délai supplémentaire raisonnable non respecté par le Vendeur.</li>
</ul>
<p className="mt-4">
    La résolution peut être immédiate si le Vendeur refuse de s'exécuter ou s'il est manifeste qu'il ne pourra pas fournir les Services ou si le délai d'exécution non respecté constituait, pour le Client, une condition essentielle de la vente.
</p>
<p className="mt-4">
    En cas de résolution de la vente, les sommes versées par le Client lui seront alors restituées au plus tard dans les quatorze jours qui suivent la date de dénonciation du contrat à l'exclusion de toute indemnisation ou retenue, sans préjudice de l'allocation éventuelle de dommages et intérêts au profit du Client.
</p>
<h3 className="text-lg font-bold mt-4">ARTICLE 7 - Modalités de résiliation du contrat</h3>
<p className="mt-4">
    Il est rappelé que, conformément aux dispositions légales, la résiliation du contrat par voie électronique est possible lorsque le contrat a été conclu par voie électronique ou, lorsqu'au jour de la résiliation le Vendeur offre aux Clients la possibilité de conclure des contrats par voie électronique.
</p>
<p className="mt-4">
    A cet effet, une fonctionnalité gratuite est mise à la disposition du Client, lui permettant d'accomplir, par voie électronique, la notification et toutes les démarches nécessaires à la résiliation du contrat, dont le Vendeur devra accuser réception en informant le Client, sur un support durable et dans un délai raisonnable, de la date à laquelle le contrat prend fin et des effets de la résiliation.
</p>
<h3 className="text-lg font-bold mt-4">ARTICLE 8 - Responsabilité du Vendeur - Garantie</h3>
<p className="mt-4">
    Les Services proposés à la vente par le Vendeur sont conformes à la réglementation en vigueur en France et ont des performances compatibles avec des usages non professionnels.
</p>
<p className="mt-4">
    Le Client bénéficie de plein droit et sans paiement complémentaire, de la garantie légale de conformité et de la garantie légale des vices cachés.
</p>
<p className="mt-4">
    En revanche, le Vendeur ne pourra être considéré comme responsable ni défaillant pour tout retard ou inexécution consécutif :
</p>
<ul className="list-disc list-inside ml-4">
    <li>à la survenance d'un cas de force majeure,</li>
    <li>au non-respect de la législation du pays dans lequel les Services sont délivrés, qu'il appartient au Client de vérifier avant passation de sa commande,</li>
    <li>en cas de mauvaise utilisation, d'utilisation à des fins professionnelles.</li>
</ul>
<h3 className="text-lg font-bold mt-4">ARTICLE 9 - Protection des données personnelles</h3>
<p className="mt-4">
    En application de la loi 78-17 du 6 janvier 1978 modifiée par la loi n°2018-493 du 20 juin 2018, il est rappelé que les données nominatives demandées au Client sont nécessaires au traitement de sa commande et à l'établissement des factures, notamment.
</p>
<p className="mt-4">
    Ces données peuvent être communiquées aux éventuels partenaires du Vendeur chargés de l'exécution, du traitement, de la gestion et du paiement des commandes.
</p>
<p className="mt-4">
    Le traitement des informations communiquées par l'intermédiaire du site internet <a href="https://btobjob.com/" className="text-blue-500">https://btobjob.com/</a> répond aux exigences légales en matière de protection des données personnelles, le système d'information utilisé assurant une protection optimale de ces données et notamment le RGPD, ainsi que toute autre disposition législative ou réglementaire en vigueur.
</p>
<p className="mt-4">
    Les modalités de collecte et de traitement des données à caractère personnel ainsi que les droits dont disposent les Clients sur celles-ci peuvent être consultés dans la rubrique « Politique de confidentialité du Vendeur », que le Client doit consulter et accepter, notamment au moment de la passation de sa commande.
</p>
<p className="mt-4">
    Le Client dispose, conformément aux réglementations nationales et européennes en vigueur d'un droit d'accès permanent, de modification, de rectification, d'opposition de portabilité et de limitation du traitement s'agissant des informations le concernant.
</p>
<p className="mt-4">
    Ce droit peut être exercé dans les conditions et selon les modalités définies sur le site internet <a href="https://btobjob.com/" className="text-blue-500">https://btobjob.com/</a>.
</p>
<p className="mt-4">
    Le Client, constatant qu'une violation au règlement général sur la protection des données personnelles aurait été commise, a la possibilité de mandater une association ou un organisme mentionné au IV de l'article 43 ter de la loi informatique et liberté de 1978, afin d'obtenir contre le responsable de traitement ou sous-traitant, réparation devant une juridiction civile ou administrative ou devant la commission nationale de l'informatique et des libertés.
</p>
<h3 className="text-lg font-bold mt-4">ARTICLE 10 - Propriété intellectuelle</h3>
<p className="mt-4">
    Le contenu du site internet <a href="https://btobjob.com/" className="text-blue-500">https://btobjob.com/</a> est la propriété du Vendeur et de ses partenaires et est protégé par les lois françaises et internationales relatives à la propriété intellectuelle.
</p>
<p className="mt-4">
    Toute reproduction totale ou partielle de ce contenu est strictement interdite et est susceptible de constituer un délit de contrefaçon.
</p>

<h3 className="text-lg font-bold mt-4">ARTICLE 11 - Imprévision</h3>
<p className="mt-4">
    En cas de changement de circonstances imprévisibles lors de la conclusion du contrat, conformément aux dispositions de l'article 1195 du Code civil, la Partie qui n'a pas accepté d'assumer un risque d'exécution excessivement onéreuse peut demander une renégociation du contrat à son cocontractant.
</p>

<h3 className="text-lg font-bold mt-4">ARTICLE 12 - Force majeure</h3>
<p className="mt-4">
    Les Parties ne pourront être tenues pour responsables si la non-exécution ou le retard dans l'exécution de l'une quelconque de leurs obligations, telles que décrites dans les présentes, découle d'un cas de force majeure, au sens de l'article 1218 du Code civil ou d'aléas sanitaires ou climatiques exceptionnels indépendants de la volonté des Parties.
</p>
<h3 className="text-lg font-bold mt-4">ARTICLE 13 - Droit applicable - Langue</h3>
<p className="mt-4">
    Les présentes Conditions générales de vente et les opérations qui en découlent sont régies par le droit français.
</p>
<p className="mt-4">
    Elles sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou plusieurs langues, seul le texte français ferait foi en cas de litige.
</p>

<h3 className="text-lg font-bold mt-4">ARTICLE 14 - Litiges</h3>
<p className="mt-4">
    Tout litige entre les parties sera porté devant le tribunal de commerce de DIJON (21000).
</p>
<p className="mt-4">
    La compétence de la juridiction désignée est exclusive de toute autre compétence, notamment :
</p>
<ul className="list-disc list-inside ml-4">
    <li>celle de la juridiction du lieu du domicile d'une partie ;</li>
    <li>celle, le cas échéant, de la juridiction indiquée sur les documents propres à une partie et échangés entre les parties à l'occasion des relations qu'elles ont nouées en vue de conclure ou d'exécuter le Contrat, telles que les conditions générales des parties.</li>
</ul>
<p className="mt-4">
    Le juge de l'urgence de la juridiction désignée est compétent pour statuer sur les demandes provisoires et conservatoires justifiées par l'urgence.
</p>
<h3 className="text-lg font-bold mt-4">ARTICLE 15 - Information précontractuelle - Acceptation du Client</h3>
<p className="mt-4">
    Le Client reconnaît avoir été informé, préalablement à la passation de sa commande et à la conclusion de la vente, d'une manière lisible et compréhensible, au moyen de la mise à disposition des présentes Conditions Générales de Vente et conformément aux dispositions de l'article L 221-5 du Code de la consommation :
</p>
<ul className="list-disc list-inside ml-4">
    <li>sur les caractéristiques essentielles des Services, compte tenu du support de communication utilisé et du Service concerné ;</li>
    <li>sur le prix des Services et l'application d'un prix personnalisé sur la base d'une prise de décision automatisée et des frais annexes ou, en l'absence de paiement d'un prix, sur tout avantage procuré au lieu ou en complément de celui-ci et sur la nature de cet avantage ;</li>
    <li>sur les modalités de paiement, de fourniture et d'exécution du contrat ;</li>
    <li>en l'absence d'exécution immédiate du contrat, sur la date ou le délai auquel le Vendeur s'engage à fournir les Services commandés ;</li>
    <li>sur l'identité du Vendeur, ses coordonnées postales, téléphoniques et électroniques, ainsi que sur ses activités, si elles ne ressortent pas du contexte ;</li>
    <li>sur les garanties légales et contractuelles et leurs modalités de mise en oeuvre ;</li>
    <li>sur la possibilité de recourir à une médiation conventionnelle en cas de litige ;</li>
    <li>sur le droit de rétractation (existence, conditions, délai, modalités d'exercice de ce droit et formulaire type de rétractation), les modalités de résiliation et autres conditions contractuelles importantes et, le cas échéant, sur les coûts de l'utilisation de la technique de communication à distance, l'existence de codes de bonne conduite et les cautions et garanties financières ;</li>
    <li>sur les moyens de paiement acceptés.</li>
</ul>
<p className="mt-4">
    Le fait pour un Client, de commander sur le site Internet <a href="https://btobjob.com/" className="text-blue-500">https://btobjob.com/</a> emporte adhésion et acceptation pleine et entière des présentes Conditions Générales de Vente et obligation au paiement des Services commandés, ce qui est expressément reconnu par le Client, qui renonce, notamment, à se prévaloir de tout document contradictoire, qui serait inopposable au Vendeur.
</p>

                <button
                    onClick={onClose}
                    className="px-4 py-2 bg-[#75C00A] text-white rounded hover:bg-[#66A309] transition duration-300 mt-4"
                >
                    Fermer
                </button>
            </div>
        </div>
    );
}

export default Terms;
