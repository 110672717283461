import React from 'react'

const Endwords = () => {
  return (
    <div className='flex flex-col items-center justify-center gap-2 bg-[#FFF]'>
         <div className="  home-last-div p-3 ">
          <div className='translatable-text'>
           <p  ClassName="    home-last-para ">nous fournissons une plate-forme dynamique permettant aux indépendants et aux professionnels <br/>de se connecter et de collaborer. Grâce à un modèle d'abonnement flexible, les utilisateurs<br/> peuvent accéder à des services tels que la location d'équipement et la location d'espace de projet.
          </p></div>
        </div>
        <div className="  Jim-div">
           <p  ClassName="    Jim-para"></p>
        </div>
    </div>
  )
}

export default Endwords
