import React, { useState } from 'react';
import './user.css';
import LOGO from '../../assests/websitelogo.png';
import { Link } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi'; // Import eye icons
import { useNavigate } from "react-router-dom";
import axios from 'axios';

function IndividualSignIn() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        kbis: null,
        insurance: null,
        civilLiability: null
    });
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State for confirm password
    const [isVerifying, setIsVerifying] = useState(false);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.password !== formData.confirmPassword) {
            alert('Passwords do not match!');
            return;
        }

        setIsVerifying(true); // Set verifying state to true

        const form = new FormData();
        form.append('name', formData.name);
        form.append('email', formData.email);
        form.append('phone', formData.phone);
        form.append('password', formData.password);

        try {
            const response = await axios.post('https://btob.btobjob.com/create-customer.php', form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true
            });

            if (response.data.status === "success") {
                alert('Signup successful!');
                localStorage.setItem('email', formData.email);
                window.location.href = 'https://btob.btobjob.com/verify_email.php';
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('There was an error! ' + error.message);
        }
    };

    return (
        <div className="  flex justify-center items-center min-h-screen shadow-2xl">
            <div className='w-96 p-2 rounded-3xl shadow-lg bg-[#E1F5C4]'>
                <h4 className='individual-heading'>Créer un compte Particulier</h4>
                <p className='individual-para'>Nous sommes heureux de vous voir</p>
                <form onSubmit={handleSubmit}>
                    <div>
                        <p className='individual-para-input'>Nom et prénom</p>
                        <div className='individual-input-center'>
                            <input className='w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none' type='text' name='name' placeholder='Entrez votre nom complet' onChange={handleChange} required />
                        </div>
                    </div>
                    <div>
                        <p className='individual-para-input'>E-mail</p>
                        <div className='individual-input-center'>
                            <input className='w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none' type='email' name='email' placeholder='E-mail' onChange={handleChange} required />
                        </div>
                    </div>
                    <div>
                        <p className='individual-para-input'>Numéro de téléphone</p>
                        <div className='individual-input-center'>
                            <input className='w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none' type='text' name='phone' placeholder='Entrez votre numéro' onChange={handleChange} required />
                        </div>
                    </div>
                    <div className="  relative">
                        <p className='individual-para-input'>Créer un mot de passe</p>
                        <div className='individual-input-center'>
                            <input
                                className='w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none'
                                type={showPassword ? 'text' : 'password'} // Toggle between text and password
                                name='password'
                                placeholder='Entrez un nouveau mot de passe'
                                onChange={handleChange}
                                required
                            />
                            <span
                                className="  absolute right-4 top-8 cursor-pointer"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <FiEyeOff /> : <FiEye />} {/* Toggle eye icon */}
                            </span>
                        </div>
                    </div>
                    <div className="  relative">
                        <p className='individual-para-input'>Confirmez le mot de passe</p>
                        <div className='individual-input-center'>
                            <input
                                className='w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none'
                                type={showConfirmPassword ? 'text' : 'password'} // Toggle confirm password visibility
                                name='confirmPassword'
                                placeholder='Confirmez le mot de passe'
                                onChange={handleChange}
                                required
                            />
                            <span
                                className="  absolute right-4 top-8 cursor-pointer"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            >
                                {showConfirmPassword ? <FiEyeOff /> : <FiEye />} {/* Toggle eye icon */}
                            </span>
                        </div>
                    </div>
                    <div className='flex px-2 items-center my-2'>
                        <input type="checkbox" required />
                        <p className='ml-2 text-xs text-[#75C00A] font-semibold flex items-center'>J'accepte toutes les déclarations incluses dans les conditions d'utilisation</p>
                    </div>
                    <div className='individual-btn-center'>
                        <button className='sinscrire-individual-btn' type='submit'>{isVerifying ? 'Verifying...' : 'S\'inscrire'}</button>
                    </div>
                </form>
                <p className='individual-or'>or</p>
                <p className='individual-Vous-paragraph-center'>Vous avez déjà un compte</p>
                <div className='individual-btn-center'>
                    <button className='seconnector-individual-btn border-2 border-lime-700'>
                        <Link className='login-link' to="/login">Se connecter</Link>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default IndividualSignIn;
