import React, { useState, useRef, useEffect } from 'react'

const PublicRequestCompleted = ({ toggleboolean2 }) => {

  const [localToggle, setLocalToggle] = useState(toggleboolean2);

  useEffect(() => {
    setLocalToggle(toggleboolean2);
  }, [toggleboolean2]);

  useEffect(() => {
    if (localToggle) {
      console.log('Form has been toggled. Fetching recommendations...');
      // Fetch recommendations or perform any other necessary actions
    }
  }, [localToggle]);


  const cardRef = useRef(null);

  const handleClickOutside = (event) => {
    if (cardRef.current && !cardRef.current.contains(event.target)) {
      setLocalToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!localToggle) return null;


  return (
    <div className={`${localToggle ? "flex" : "hidden"} items-center justify-center fixed inset-0 z-10 bg-black/50`}>
      <div className="  fixed inset-0 flex items-center justify-center ">
        <div ref={cardRef} className="  max-w-sm mx-auto bg-white p-6 rounded-2xl shadow-lg relative">
          <div className="  absolute -top-8 left-1/2 transform -translate-x-1/2 w-16 h-16 bg-[#65A406] rounded-full flex items-center justify-center">
            <svg className="  w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
            </svg>
          </div>

          <div className="  text-center mt-8">
            <p className='my-2'>Votre demande est soumise</p>
            <button
            onClick={()=>(setLocalToggle(!localToggle))}
              type="button"
              className="  w-full py-2 px-2 bg-gradient-to-r from-lime-400 to-[#65A406] text-white font-semibold rounded-md hover:bg-green-700"
            > 
             D'accord
            </button>
          </div>
        </div>
      </div>
    </div>)
}

export default PublicRequestCompleted;




