import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaHeart } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const AllPhotos = () => {
    const navigate = useNavigate();
    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                const response = await axios.get('https://btob.btobjob.com/get_photos.php', { withCredentials: true });
                if (Array.isArray(response.data)) {
                    setPhotos(response.data);
                } else {
                    throw new Error('Unexpected response format');
                }
            } catch (error) {
                console.error("Error fetching photos:", error);
                setError("Error fetching photos");
            } finally {
                setLoading(false);
            }
        };

        fetchPhotos();
    }, []);

    const NavigateToBack = () => {
        navigate(-1); // Navigates back one step in the history stack
    };

    const navigateToAddPhotos = () => {
        window.location.href = "https://btob.btobjob.com/add-photos.php"; // Redirect to add photos page
    };

    return (
        <div className="  mx-auto md:max-w-3xl lg:max-w-4xl bg-white rounded-lg md:mt-16">
            <div className='flex flex-col'>
                <div className='p-6 border-b-2 flex flex-col'>
                    <div onClick={NavigateToBack} className='flex items-center cursor-pointer'>
                        <FaArrowLeft className='h-6 w-6 pr-2' />
                         <p  ClassName="    text-xl font-semibold">Toutes les photos</p>
                    </div>
                    <p className='text-xs'>Voici quelques photos récentes</p>
                </div>

                {loading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3'>
                        {photos.map((photo) => (
                            <div key={photo.id} className="  p-4">
                                <div className="  bg-white border rounded-lg w-full">
                                    <img src={`https://btob.btobjob.com/${photo.photo_url}`} className='w-full rounded-tl-lg rounded-tr-lg object-contain' alt="User Photo" />
                                    <div className="  flex items-center justify-between mx-4 mt-3 mb-2">
                                       
                                       
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                <div className='p-6'>
                    <button 
                        onClick={navigateToAddPhotos}
                        className='bg-lime-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                    >
                        Ajouter des Photos
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AllPhotos;
