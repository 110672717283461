import React, { useState, useEffect } from 'react';
import { FaMessage } from "react-icons/fa6";
import { HiSignal } from "react-icons/hi2";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ForumHome = () => {
  const [topicsMatching, setTopicsMatching] = useState([]);
  const [topicsNotMatching, setTopicsNotMatching] = useState([]);
  const [showAllMatching, setShowAllMatching] = useState(false);
  const [showAllNotMatching, setShowAllNotMatching] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchForumTopics = async () => {
      try {
        const response = await axios.get('https://btob.btobjob.com/fetch-forum-topics-by-id.php', { withCredentials: true });
        setTopicsMatching(response.data.matching || []);
        setTopicsNotMatching(response.data.not_matching || []);
      } catch (error) {
        console.error('Error fetching forum topics:', error);
      }
    };

    fetchForumTopics();
  }, []);

  const AddForum = () => {
    navigate("/profile/addforum");
  };

  const viewTopicDetail = (topicId) => {
    navigate(`/profile/forumdetail/${topicId}`);
  };

  const displayedMatchingTopics = topicsMatching.slice(0, showAllMatching ? topicsMatching.length : 3);
  const displayedNotMatchingTopics = topicsNotMatching.slice(0, showAllNotMatching ? topicsNotMatching.length : 3);

  return (
    <div className="md:mx-auto m-2 max-w-6xl md:mt-12 rounded-lg">
      <div className="flex flex-col gap-4 bg-white rounded-md p-4 mb-6">
        <p className="text-2xl font-bold">Bienvenue!</p>
        <p>Ajoutez un nouveau sujet, discutez-en et partagez également vos opinions avec les autres..</p>
        <button onClick={AddForum} className="px-4 py-2 text-white bg-[#65A406] rounded-md">Ajouter un nouveau sujet</button>
      </div>

      {/* Main container with flex to make equal width sections */}
      <div className="flex flex-col md:flex-row gap-4">
        
        {/* "Vos sujets" (Your Topics) section */}
        <div className="flex-1 bg-white rounded-md p-4">
          <p className="text-2xl font-bold mb-2">Vos sujets</p>
          {displayedMatchingTopics.map(topic => (
            <div
              key={topic.id}
              className="flex flex-row justify-between w-full p-2 bg-gray-100 rounded-md cursor-pointer mb-2"
              onClick={() => viewTopicDetail(topic.id)}
            >
              <div className="flex items-center gap-2">
                <FaMessage className="h-5 w-5 sm:h-7 sm:w-7" />
                <p className="font-bold text-xs sm:text-base">{topic.heading}</p>
              </div>
              <div className="flex items-center gap-3">
                <div className="flex flex-col items-start">
                  <p className="sm:text-xs text-[9px]">{topic.description}</p>
                  <p className="font-bold sm:text-xs text-[9px]">détails: {topic.replies}</p>
                </div>
                <div>
                  <HiSignal color="orange" />
                </div>
              </div>
            </div>
          ))}
          {topicsMatching.length > 3 && !showAllMatching && (
            <button onClick={() => setShowAllMatching(true)} className="px-4 py-2 text-white bg-[#65A406] rounded-md mt-2">Voir Tout</button>
          )}
        </div>

        {/* "Autres sujets" (Other Topics) section */}
        <div className="flex-1 bg-white rounded-md p-4">
          <p className="text-2xl font-bold mb-2">Autres sujets</p>
          {displayedNotMatchingTopics.map(topic => (
            <div
              key={topic.id}
              className="flex flex-row justify-between w-full p-2 bg-gray-100 rounded-md cursor-pointer mb-2"
              onClick={() => viewTopicDetail(topic.id)}
            >
              <div className="flex items-center gap-2">
                <FaMessage className="h-5 w-5 sm:h-7 sm:w-7" />
                <p className="font-bold text-xs sm:text-base">{topic.heading}</p>
              </div>
              <div className="flex items-center gap-3">
                <div className="flex flex-col items-start">
                  <p className="sm:text-xs text-[9px]">{topic.description}</p>
                  <p className="font-bold sm:text-xs text-[9px]">détails: {topic.replies}</p>
                </div>
                <div>
                  <HiSignal color="orange" />
                </div>
              </div>
            </div>
          ))}
          {topicsNotMatching.length > 3 && !showAllNotMatching && (
            <button onClick={() => setShowAllNotMatching(true)} className="px-4 py-2 text-white bg-[#65A406] rounded-md mt-2">Voir Tout</button>
          )}
        </div>
        
      </div>
    </div>
  );
};

export default ForumHome;
