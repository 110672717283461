import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const EditPost = () => {
  const { id: postId } = useParams();
  const navigate = useNavigate();

  const [post, setPost] = useState(null);
  const [formData, setFormData] = useState({ text: '', images: [] });
  const [initialImages, setInitialImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const { data } = await axios.get(`https://btob.btobjob.com/fetch-posts-by-id.php/?id=${postId}`);
        setPost(data);
        setFormData({ text: data.text || '', images: [] });
        setInitialImages(data.images || []); // Contains {id, url} objects
      } catch (error) {
        setError('Failed to fetch the post. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchPost();
  }, [postId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files).filter((file) => file.size <= 2 * 1024 * 1024);
    setFormData((prevData) => ({ ...prevData, images: files }));
  };

  const handleImageDelete = async (imageId) => {
    const formData = new FormData();
    formData.append('postId', postId);
    formData.append('imageIdToDelete', imageId);

    try {
      const response = await axios.post(
        'https://btob.btobjob.com/delete-images.php',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      if (response.status === 200) {
        setInitialImages(initialImages.filter((img) => img.id !== imageId));
      } else {
        setError('Failed to delete the image. Please try again.');
      }
    } catch (error) {
      setError('Error deleting image. Check server connection.');
      console.error('Error deleting image:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const form = new FormData();
    form.append('text', formData.text);
    formData.images.forEach((image, index) => {
      form.append(`images[${index}]`, image);
    });

    try {
      const response = await axios.post(`https://btob.btobjob.com/update-post.php/?id=${postId}`, form, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.status === 200) {
        navigate(`/profile/viewprofile`);
      } else {
        setError('Failed to update post. Please try again.');
      }
    } catch (error) {
      setError('Error updating post. Please try again later.');
      console.error('Error updating post:', error);
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="max-w-xl mx-auto bg-white p-6 rounded-lg shadow-md mt-20">
      <h1 className="text-2xl font-semibold mb-4">Edit Post</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">Post Text</label>
          <textarea
            name="text"
            value={formData.text}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            rows="4"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Upload Images</label>
          <input
            type="file"
            multiple
            onChange={handleImageChange}
            className="w-full p-2 border rounded"
            accept="image/*"
          />
          <p className="text-sm text-gray-600">Max file size: 2MB.</p>
          {initialImages.length > 0 && (
            <div className="mt-2">
              <p className="text-sm text-gray-600">Current Images:</p>
              <div className="flex gap-2 flex-wrap">
                {initialImages.map((image) => (
                  <div key={image.id} className="relative">
                    <img
                      src={image.url}
                      alt={`Current Post Image ${image.id}`}
                      className="w-20 h-20 object-cover rounded-md"
                    />
                    <input type="hidden" name="imageId" value={image.id} />
                    <button
                      type="button"
                      onClick={() => handleImageDelete(image.id)}
                      className="absolute top-0 right-0 bg-red-500 text-white text-xs px-1 rounded"
                    >
                      X
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <button type="submit" className="px-4 py-2 bg-lime-500 text-white rounded hover:bg-blue-600">
          Update Post
        </button>
      </form>
    </div>
  );
};

export default EditPost;
