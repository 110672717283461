import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaArrowLeft, FaMapMarkerAlt } from "react-icons/fa";
import { CiWallet, CiCalendar } from "react-icons/ci";
import Share from '../Share';
import MessageModal from '../OfferPage/MessageModal';
import api from '../../services/api';

const RentalAdsDetails = () => {
    const { rentalId } = useParams();
    const navigate = useNavigate();
    const [rentalData, setRentalData] = useState(null);
    const [openShareModal, setOpenShareModal] = useState(false);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

    useEffect(() => {
        const fetchRentalDetails = async () => {
            try {
                const response = await axios.get('https://btob.btobjob.com/get_rental_ad.php', {
                    params: { id: rentalId },
                    withCredentials: true,
                });
                setRentalData(response.data);
            } catch (error) {
                console.error("Error fetching rental details:", error);
            }
        };
        fetchRentalDetails();
    }, [rentalId]);

    const handleContactClick = () => {
        if (rentalData && rentalData.user_id) {
            navigate(`/profile/specificUser?professional_id=${rentalData.user_id}`);
        }
    };

    const handleSendMessage = async (message) => {
        if (!rentalData?.user_id) {
            console.error("User ID not found for the rental ad.");
            return;
        }

        if (!message || message.trim() === "") {
            console.error("Message content is empty. Please provide a valid message.");
            return;
        }

        try {
            const fullMessage = `(Rental Title: ${rentalData.title})\n ${message}`; // Add rental title to the message

            const response = await api.sendMessagee({
                receiverId: rentalData.user_id,
                message: fullMessage,
            });

            console.log("Message sent response:", response);

            if (response.success) {
                alert("Message sent successfully!");
                setIsMessageModalOpen(false);
            } else {
                console.error("Failed to send message:", response.message);
                alert("Failed to send message. Please try again.");
            }
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };

    const currentUrl = window.location.href;

    if (!rentalData) return <p>Loading...</p>;

    return (
        <div className="max-w-7xl mx-auto p-6 mt-10 bg-gray-100">
            <button
                onClick={() => navigate(-1)}
                className="mb-6 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
            >
                <FaArrowLeft className="inline mr-2" /> Back
            </button>
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="p-6 flex justify-between items-center">
                    <h2 className="text-3xl font-bold text-gray-800">{rentalData.title}</h2>
                    <button
                        onClick={() => setOpenShareModal(true)}
                        className="px-4 py-2 bg-gray-200 text-gray-800 rounded-full hover:bg-gray-300 transition"
                    >
                        Share
                    </button>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 p-6">
                    <div className="lg:col-span-2">
                        <h3 className="text-xl font-semibold mb-4">Rental Description</h3>
                        <p className="text-gray-700 mb-6">{rentalData.description || 'No description available.'}</p>
                        <div className="flex flex-col gap-y-4">
                            <div className="flex items-center">
                                <CiWallet size={24} color="#65A406" />
                                <span className="ml-2 text-lg font-semibold text-gray-800">€{rentalData.price}</span>
                            </div>
                            <div className="flex items-center">
                                <CiCalendar size={24} color="#65A406" />
                                <span className="ml-2 text-lg text-gray-500">Created At: {new Date(rentalData.created_at).toLocaleDateString()}</span>
                            </div>
                            <div className="flex items-center">
                                <FaMapMarkerAlt size={24} color="#65A406" />
                                <span className="ml-2 text-lg font-semibold text-gray-800">{rentalData.location || "Location not specified"}</span>
                            </div>
                            <div className="p-6 text-center">
                                <button 
                                    onClick={handleContactClick}
                                    className="px-6 py-2 bg-lime-500 text-white font-semibold rounded-lg hover:bg-lime-700 transition"
                                >
                                    Contact
                                </button>
                                <button 
                                    onClick={() => setIsMessageModalOpen(true)}
                                    className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-700 transition ml-4"
                                >
                                    Send Message
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-1">
                        {rentalData.images && rentalData.images.length > 0 ? (
                            rentalData.images.map((image, index) => (
                                <img 
                                    key={index}
                                    src={image}
                                    alt={`${rentalData.title} image ${index + 1}`}
                                    className="w-full h-80 object-cover rounded-lg shadow-sm mb-4"
                                />
                            ))
                        ) : (
                            <img 
                                src="fallback-image-url" 
                                alt="Fallback image" 
                                className="w-full h-80 object-cover rounded-lg shadow-sm"
                            />
                        )}
                    </div>
                </div>
            </div>

            {openShareModal && (
                <Share
                    profileUrl={currentUrl}
                    closeModal={() => setOpenShareModal(false)}
                />
            )}

            {isMessageModalOpen && (
                <MessageModal
                    isOpen={isMessageModalOpen}
                    onClose={() => setIsMessageModalOpen(false)}
                    onSend={handleSendMessage}
                />
            )}
        </div>
    );
};

export default RentalAdsDetails;
