import React, { useState, useEffect, useRef } from 'react'
import { MdPeopleAlt } from "react-icons/md";
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
const Recommend = ({ toggleboolean }) => {

  const [localToggle, setLocalToggle] = useState(toggleboolean);

  useEffect(() => {
    setLocalToggle(toggleboolean);
  }, [toggleboolean]);

  useEffect(() => {
    if (localToggle) {
      console.log('Form has been toggled. Fetching recommendations...');
      // Fetch recommendations or perform any other necessary actions
    }
  }, [localToggle]);
  

  const navigate = useNavigate();

  const handleButtonClick = () => {
      navigate("/profile/offers");
  };



  return (
    <div>
      <div className={`${localToggle  ? "flex" : "hidden"} items-center justify-center fixed inset-0 z-10 bg-black/50`}>
        <div className="   max-w-md  bg-white shadow-md rounded-lg p-6 mx-2 sm:mx-auto">
          <div className='flex items-center justify-between'>
            <div className="  text-lg font-semibold">Recommander un fournisseur</div>
            <MdOutlineCancel onClick={()=>(setLocalToggle(!localToggle))} className="  w-6 h-6"/>
          </div>
          <div className="  text-gray-800 mt-2">Connaissez-vous un fournisseur que vous réponde à cette demande ?</div>
          <div className="  flex justify-center mt-4">
            <MdPeopleAlt size={50} />
          </div>
          <div className="  text-gray-800 mt-4 text-center">Aucune recommandation pour le moment</div>
          <div>
            <button onClick={handleButtonClick} className="   w-full rounded-xl bg-gradient-to-r text-white from-lime-400 to-lime-600 px-2 py-2 flex items-center justify-center" >
              Recommander maintenant
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Recommend
