import React, { useState, useEffect } from 'react';
import { FaSearch } from "react-icons/fa";
import { IoLayersOutline } from "react-icons/io5";
import { TfiAnnouncement } from "react-icons/tfi";
import { CiVideoOn, CiLocationOn } from "react-icons/ci";
import { MdWorkOutline } from "react-icons/md";
import { FaCircle } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';

const PostServices = () => {
    const [jobs, setJobs] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [workPlaceQuery, setWorkPlaceQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [viewType, setViewType] = useState('jobs');
    const [radius, setRadius] = useState(0); // Default 0 to show all jobs
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const jobTypes = [
        { type: "Stage", icon: MdWorkOutline },
        { type: "Emploi", icon: IoLayersOutline },
        { type: "Formation", icon: TfiAnnouncement },
        { type: "Apprentissage", icon: CiVideoOn },
    ];

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let response;
    
                // Fetch data based on view type
                if (viewType === 'jobs') {
                    response = await api.fetchJobs({ searchQuery, workPlaceQuery, radius });
                    setJobs(response.jobs || []);
                    setFilteredJobs(response.jobs || []);
                } else if (viewType === 'job_seekers') {
                    response = await api.fetchJobSeekers({ searchQuery, workPlaceQuery, radius });
                    setJobs(response.jobSeekers || []); // Correctly set jobSeekers
                    setFilteredJobs(response.jobSeekers || []);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setError("Failed to load data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [searchQuery, workPlaceQuery, radius, viewType]);

    const handleSearchChange = (e) => setSearchQuery(e.target.value);
    const handleWorkPlaceChange = (e) => setWorkPlaceQuery(e.target.value);
    const handleCategoryClick = (category) => setSelectedCategory(category);
    const navigateToJobDescription = (job) => navigate(`/profile/${job.ad_id ? `adsdescription/${job.ad_id}` : `jobdescription/${job.ID}`}`);

    return (
        <div className='flex flex-col max-w-8xl items-start gap-10 mx-auto p-12'>
            <div className='w-full md:flex grid grid-cols-1 gap-4 bg-white px-2 justify-between py-2'>
                <div className='flex items-center md:w-1/2 w-full'>
                    <FaSearch className="text-blue-400" />
                    <input
                        type="text"
                        placeholder='Search by name, description, etc.'
                        className="outline-none px-2 py-2 w-full text-base"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                </div>
                <div className='flex items-center md:w-1/2 w-full'>
                    <CiLocationOn className="text-blue-400" />
                    <input
                        type="text"
                        placeholder='Search by workplace location'
                        className="outline-none px-2 py-2 w-full text-base"
                        value={workPlaceQuery}
                        onChange={handleWorkPlaceChange}
                    />
                </div>
                <div className='flex items-center md:w-1/2 w-full'>
                    <FaCircle className="text-blue-400" />
                    <input 
                        type="text" 
                        min="0" 
                        max="100" 
                        value={radius} 
                        onChange={(e) => setRadius(Number(e.target.value))} 
                        className="outline-none px-2 py-2 w-full text-base"
                    />
                </div>
                <select 
                    className="text-xs font-semibold bg-[#65A406] text-white px-2 py-2 rounded-md"
                    value={viewType}
                    onChange={(e) => setViewType(e.target.value)}
                >
                    <option value="jobs">Jobs</option>
                    <option value="job_seekers">Job Seekers</option>
                </select>
            </div>

            <p className='sm:text-3xl text-xl font-bold'>Explore by <span className='text-[#65A406]'>Job Type</span></p>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full'>
                {jobTypes.map((jobType, index) => {
                    const IconComponent = jobType.icon;
                    return (
                        <div key={index} className='flex items-center gap-2 bg-white rounded-lg p-4 cursor-pointer' onClick={() => handleCategoryClick(jobType.type)}>
                            <IconComponent size={36} color='#65A406' />
                            <div className='flex flex-col items-start'>
                                <p className='font-bold text-xl'>{jobType.type}</p>
                                <p>{jobs.filter(job => job.category && job.category.includes(jobType.type)).length} available</p>
                            </div>
                        </div>
                    );
                })}
            </div>

            {loading ? (
                <p>Loading data...</p>
            ) : error ? (
                <p className="text-red-500">{error}</p>
            ) : (
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 w-full gap-x-4 gap-y-12'>
                    {filteredJobs.length > 0 ? filteredJobs.map((job, index) => (
                        <div onClick={() => navigateToJobDescription(job)} key={index} className="mx-auto bg-white w-full shadow-lg rounded-lg overflow-hidden p-4 relative">
                            <div className="px-6 py-4">
                                <h2 className="text-xl font-bold text-gray-800">{job.job_title}</h2>
                                <p className="text-gray-600">{job.category}</p>
                                <div className="flex items-center mt-2">
                                    <span className="px-2 py-1 text-xs font-semibold leading-tight text-green-700 bg-green-100 rounded-full">{job.category ? job.category.toUpperCase() : 'JOB'}</span>
                                    {job.salary && <span className="ml-2 text-gray-600">Salary: {job.salary}€</span>}
                                </div>
                                {job.boosted === 1 && (
                                    <div className="absolute top-0 right-0 bg-red-600 text-white text-xs px-2 py-1 rounded-md">
                                        Urgent
                                    </div>
                                )}
                                <div className="flex items-center mt-4">
                                    <img className="w-10 h-10 object-cover rounded-full" src={job.ProfilePicture} alt="company logo" />
                                    <div className="ml-4 flex pt-5">
                                        <CiLocationOn />
                                        <p className="text-gray-800 font-semibold">{job.work_place || 'Unknown Company'}</p> 
                                    </div>
                                </div>
                                <p className="text-gray-800">{job.created_at || 'No time'}</p>
                            </div>
                        </div>
                    )) : <p>No jobs available.</p>}
                </div>
            )}
        </div>
    );
};

export default PostServices;
